import React from 'react'
import { getCategory } from '../../../api'

export default WrappedComponent => class WithCategoryApi extends React.Component{
  state = {
    category: null,
    loaded: false
  }

  async componentDidMount(){
    const { match } = this.props
    if(match.params && Object.entries(match.params).length){
      try{
        const categoryResponse = await getCategory(match.params['catId'])
        this.setCategory(categoryResponse)
        this.setIsLoaded(true)
        // console.log('resp',categoryResponse)
      } catch {
        console.log('err during category fetch')
      }
    }else{
     this.props.history.push('/diets') 
    }
  }
  setIsLoaded = loaded => this.setState(_ => ({ loaded }))
  setCategory = category => this.setState(_ => ({ category }))

  render(){
    return <WrappedComponent {...this.state} {...this.props} />
  }
}
import React, { Component } from "react";
import play_icon from "../../../assets/images/icons/play_icon.svg";
import "../meditation.scss";
import Loading from "../../loading";
import Audio from "../Audio";
import { CSSTransition } from "react-transition-group";
import SVGInject from "@iconfu/svg-inject";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../store/actions";

import route from "../route";

import { getBGs } from "../api";

class MeditationVideo extends Component {
  state = {
    openedSounds: false,
    BGs: [],
    currentBG: "",
    currentIndex: 0,
    loaded: false,
    prepared: false
  };
  componentDidMount() {
    getBGs().then(resp => {
      this.setState(
        {
          BGs: resp.YOGA_APP.map((itm, i) => {
            !i ? (itm.isActive = true) : (itm.isActive = false);
            return itm;
          })
        },
        () => {
          this.setState(
            { currentBG: this.state.BGs[0].static, loaded: true },
            () => {
              setTimeout(() => this.setState({ prepared: true }));
            }
          );
          this.props.actions.handleHeaderConfig(route[0].headerConfig);
        }
      );
    });
  }
  setConfig = target => {
    SVGInject(target, {
      afterInject: (img, svg) => {
        svg.querySelector("use").setAttribute("fill", "#ffffff");
      }
    });
  };

  onBGChange = i => {
    this.setState({ currentBG: this.state.BGs[i].static, currentIndex: i });
    let newBGs = this.state.BGs.map(itm => {
      itm.isActive = false;
      return itm;
    });
    newBGs[i].isActive = true;
    this.setState({ BGs: newBGs });
  };

  togglePlay = () => {
    this.state.currentBG === this.state.BGs[this.state.currentIndex].static
      ? this.setState({
          currentBG: this.state.BGs[this.state.currentIndex].gif
        })
      : this.setState({
          currentBG: this.state.BGs[this.state.currentIndex].static
        });
    this.forceUpdate();
    console.log(
      "toggle",
      this.state.currentBG === this.state.BGs[this.state.currentIndex].static
    );
  };

  render() {
    return this.state.loaded ? (
      <CSSTransition
        in={this.state.prepared}
        timeout={500}
        classNames="swipe-left"
      >
        <div>
          <div
            className={
              "visual" +
              (!this.props.meditation.soundsVisibility ? " visible" : "")
            }
            style={{ backgroundImage: "url(" + this.state.currentBG + ")" }}
          >
            {this.state.currentBG ===
              this.state.BGs[this.state.currentIndex].static && (
              <div className="visual__play_btn" onClick={this.togglePlay}>
                <img
                  src={play_icon}
                  alt="icon-play"
                  onLoad={e => this.setConfig(e.target)}
                />
              </div>
            )}
            <div className="visual__touchable" onClick={this.togglePlay}></div>
            <div className="visual__navigation_panel">
              {/* <div className="visual__navigation_panel__title">Water</div>
              <div className="visual__navigation_panel__descr">
                Lorem ipsum dolor sit amet, con sectetur adipiscing elit, sed do
                eiusmod
              </div> */}
              <div className="visual__navigation_panel__list">
                {this.state.BGs.map((itm, i) => (
                  <div
                    key={i}
                    style={{ backgroundImage: "url(" + itm.static + ")" }}
                    onClick={() => this.onBGChange(i)}
                    className={
                      "visual__navigation_panel__list__item " +
                      (itm.isActive ? "active" : "")
                    }
                  />
                ))}
              </div>
            </div>
          </div>
          <div
            className={
              "sounds " +
              (this.props.meditation.soundsVisibility ? " opened" : "")
            }
          >
            <CSSTransition
              in={this.props.meditation.soundsVisibility}
              timeout={500}
              classNames="common"
            >
              <Audio injected={true} />
            </CSSTransition>
          </div>
        </div>
      </CSSTransition>
    ) : (
      <Loading />
    );
  }
}

export default connect(
  state => ({
    navConfig: state.navConfig,
    filter: state.filter,
    meditation: state.meditation
  }),
  dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
)(MeditationVideo);

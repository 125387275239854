import React from 'react'
import {getDiets} from '../api'

export default WrappedComponent => class WithDietsApi extends React.Component{
  state = {
    diets: [],
    loaded: false,
  }
  async componentDidMount(){
    try{
      const dietsResponse = await getDiets()
      this.setDiets(dietsResponse)
      this.setIsLoaded(true)
    } catch {
      console.log('err during diets fetch')
    }
  }

  setIsLoaded = loaded => this.setState(_ => ({ loaded }))
  setDiets = diets => this.setState(_ => ({ diets }))

  render(){
    return <WrappedComponent {...this.state} {...this.props} />
  }
}
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom"
import animation from "lottie-web"

import { translate } from "../../utils/location"
import * as actions from "../../store/actions";
import store from "../../store"

import route from "./route";

import "../home/home.scss"

class HomePage extends Component {
  state = {
    unsubscribe: undefined,
    langInitialized: false,
    welcomeTextFallback: `You are successfully subscribed to Yoga for You service.`,
    welcomeText: ''
  }
  componentDidMount() {
    this.props.actions.handleHeaderConfig(route[0].headerConfig);
    animation.loadAnimation({
      container: document.querySelector('.home__logo__back'),
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: 'logo.json'
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      this.setState((prev) => ({ welcomeText: translate('welcome_text', prev.welcomeTextFallback) }))
    }
  }

  render() {
    const { welcomeText } = this.state
    return (
      <div className="home">
        <div className="home__screen" >
          <div className="home__logo">
            <div className="home__logo__back" style={{ marginBottom: 15 }}></div>
          </div>
          <div className="home__descr side_padding" style={{padding: 15}}>
            {welcomeText}
          </div>
          <Link to="/">
            <button className="btn" style={{ backgroundColor: 'rgb(221, 221, 221)', color: 'white' }}>Home</button>
          </Link>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    lang: state.lang
  }),
  dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
)(HomePage);

import {translate} from "../../../utils/location"

import yoga_icon from '../../../assets/images/icons/yoga_icon_transparent.svg'
import star_icon from '../../../assets/images/icons/star_icon_transparent.svg'
import lotus_icon from '../../../assets/images/icons/lotus_icon_transparent.svg'
import book_icon from '../../../assets/images/icons/book_icon_transparent.svg'
import b1 from "../../../assets/images/templates/home/b1.png"
import b2 from "../../../assets/images/templates/home/b2.png"
import b3 from "../../../assets/images/templates/home/b3.png"
import b4 from "../../../assets/images/templates/home/b4.png"
export default [
    {
        link: '/poses',
        banner: b1,
        icon: yoga_icon,
        title: 'Yoga Poses',
        subtitle: 'Lorem ipsum',
        description: `We have collected best asanas of different yoga schools. Our certified yoga trainers bring the world of yoga closer to you!`,
        buttonLabel: translate('home_cards_poses_btn','Start Training'),
        buttonColor: '#8945ff',
        background: 'linear-gradient(40deg, #4e23ae 0%,  #923eff 70%)',
        titleKey: 'sidebar_poses_title',
        titleFallback: 'Yoga Poses',
        btnKey: 'home_cards_poses_btn',
        btnFallback: 'Start Training',
        descrKey: 'home_cards_poses_descr',
        descrFallback: 'We have collected best asanas of different yoga schools. Our certified yoga trainers bring the world of yoga closer to you!'
    },
    {
        link: '/programs',
        banner: b2,
        icon: star_icon,
        title: 'Yoga Programs',
        subtitle: 'Lorem ipsum',
        description: `Our yoga programs cultivate strength, flexibility, balance and energy. Link breath and movement to create a connection between mind, body and self in our yoga trainings.`,
        buttonLabel: translate('home_cards_programs_btn','View Programs'),
        buttonColor: '#f19340',
        background: 'linear-gradient(225deg, #ffc758 0%, #dc783d 100%)',
        titleKey: 'sidebar_programs_title',
        titleFallback: 'Yoga Programs',
        btnKey: 'home_cards_programs_btn',
        btnFallback: 'View Programs',
        descrKey: 'home_cards_programs_descr',
        descrFallback: 'Our yoga programs cultivate strength, flexibility, balance and energy. Link breath and movement to create a connection between mind, body and self in our yoga trainings.'
    },
    {
        link: '/visual-meditation',
        banner: b3,
        icon: lotus_icon,
        title: 'Meditation',
        subtitle: 'Lorem ipsum',
        description: `Enjoy 12 meditation sounds and backgrounds which will help you to improve concentration during meditation.`,
        buttonLabel: translate('home_cards_meditation_btn','Start Meditation'),
        buttonColor: '#4bddff',
        background: 'linear-gradient(43deg, #4193bd 0%, #96dff7 100%)',
        titleKey: 'sidebar_meditation_title',
        titleFallback: 'Meditation',
        btnKey: 'home_cards_meditation_btn',
        btnFallback: 'Start Meditation',
        descrKey: 'home_cards_meditation_descr',
        descrFallback: 'Enjoy 12 meditation sounds and backgrounds which will help you to improve concentration during meditation.'
    },
    {
        link: '/theory',
        banner: b4,
        icon: book_icon,
        title: 'Theory & Practice',
        subtitle: 'Lorem ipsum',
        description: `Read articles about yoga history, philosophy and it’s core principles which will benefit you intellectually, spiritually, morally and will broaden your perspective on life.`,
        buttonLabel: translate('home_cards_theory_btn','Start reading'),
        buttonColor: '#0edbb1',
        background: 'linear-gradient(to top, #0d9a7b 0%, #9affc5 100%)',
        titleKey: 'sidebar_theory_title',
        titleFallback: '',
        btnKey: 'home_cards_theory_btn',
        btnFallback: 'Theory & Practice',
        descrKey: 'home_cards_theory_descr',
        descrFallback: 'Read articles about yoga history, philosophy and it’s core principles which will benefit you intellectually, spiritually, morally and will broaden your perspective on life.'
    }
]

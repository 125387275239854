import React from "react";
import { Flipper } from "react-flip-toolkit";
import SVGInject from "@iconfu/svg-inject";
import "../poses.scss";

import { translate } from "../../../utils/location"

import { FullScreenPose } from "./FullScreen";
import { Pose } from "./Pose";

export default function PosesList(props) {
  const onLoadFavoriteIcon = (elem, pose) => {
    SVGInject(elem, {
      afterInject: (img, svg) => {
        svg.querySelector('use').setAttribute('fill', pose.colors[0])
      }
    })
  }

  const {
    list,
    fullscreen,
    toggleFullscreen,
    toggleInfoCharVisibility,
    toggleFavorite,
    lang,
    loaded
  } = props;

  return (
    <Flipper duration={750} flipKey={fullscreen}>
      {list.length ? (
        <div className="poses_list">
          {list.map((pose, i) => (
            <div key={i}>
              {pose.fullscreen ? (
                <FullScreenPose
                  lang={lang}
                  pose={pose}
                  i={i}
                  toggleFullScreen={toggleFullscreen}
                  beforeEnter={() => (document.body.style.overflow = "")}
                  handleVisibility={toggleInfoCharVisibility}
                  toggleFavorite={toggleFavorite}
                  onLoadFavorite={onLoadFavoriteIcon}
                />
              ) : (
                  <Pose
                    pose={pose}
                    i={i}
                    toggleFullScreen={toggleFullscreen}
                    toggleFavorite={toggleFavorite}
                    onLoadFavorite={onLoadFavoriteIcon}
                  />
                )}
            </div>
          ))}
        </div>
      ) : (
          loaded && (
            <div style={{ textAlign: "center", paddingTop: 100 }}>
              <h1>{translate('list_is_empty', 'List is empty')}</h1>
            </div>
          )
        )}
    </Flipper>
  );
}

import React from 'react'
import { translate } from '../../../../utils/location'

import { resetPass } from '../../api'

export default class ResetPass extends React.Component {
  state = {
    passResponse: '',
  }
  setPassResponse = (passResponse) => this.setState((_) => ({ passResponse }))

  onReset = async () => {
    const resetPassResponse = await resetPass(this.props.locale)
    this.setPassResponse(resetPassResponse.data)

    console.log('reset response', resetPassResponse)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.passWasChangedManually !== this.props.passWasChangedManually
    ) {
      this.setPassResponse('')
    }
  }

  render() {
    const { passResponse } = this.state
    return (
      <React.Fragment>
        {passResponse ? (
          <div style={{ marginBottom: 15, paddingLeft: 32, paddingRight: 32 }}>
            <label style={{ fontWeight: 'bolder' }}>
              <p>{translate('new_password', 'New password')}</p>
              <input
                style={{ textAlign: 'center', margin: '10px 0' }}
                type="text"
                defaultValue={passResponse}
              />
            </label>
          </div>
        ) : (
          <button
            disabled={passResponse}
            onClick={this.onReset}
            className="reset-pass btn"
            style={{ marginBottom: 15 }}
          >
            {translate('forgot_password_reset', 'Forgot password? Reset')}
          </button>
        )}
      </React.Fragment>
    )
  }
}

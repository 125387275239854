import React from 'react'

export default class Content extends React.Component {
  state = {
    content: ''
  }

  componentDidUpdate(prevProps){
    if(prevProps.match.params !== this.props.match.params){
      this.loadContent()
    }
  }

  loadContent = () => {
    const { match } = this.props
    const { params } = match
    console.log(params.locale,params.fileName)
    if (params.locale && params.fileName) {
      import(`html-loader?interpolate!../../assets/static/html/${params.locale}/${params.fileName.toLowerCase()}.html`)
        .then(content => {
          this.setState(() => ({ content: content.default }))
        })
        .catch(err => {
          console.log(err)
          this.setState(() => ({ content: 'file not found' }))
        })
    }
  }

  componentDidMount() {
    this.loadContent()
  }

  render() {
    const { content } = this.state
    return (
      <div
        style={{ padding: 15 }}
        dangerouslySetInnerHTML={{ __html: content }}
      >
      </div>
    )
  }
}
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import SVGInject from '@iconfu/svg-inject'
import i18next from 'i18next'

import Filter from '../filter'
import SelectLang from './components/SelectLang'

import { CSSTransition } from 'react-transition-group'

import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import * as actions from '../../store/actions'
import store from '../../store'

import { translate } from '../../utils/location'

import navItems from './linksList'
import multiLangOptions from './multi-lang-options'

import './navigation.scss'

import logo from '../../assets/images/logo/logo_small.png'

import close_button from '../../assets/images/close_btn.svg'
import burger from '../../assets/images/burger.svg'
import search from '../../assets/images/icons/search_icon.svg'
import filterIcon from '../../assets/images/filter_button.svg'
import arrow_back from '../../assets/images/icons/arrow_button_green.svg'
import soundButton from '../../assets/images/sound_button.svg'
import person from '../../assets/images/icons/person_icon.svg'

const setConfig = e => {
  SVGInject(e.target, {
    afterInject: (img, svg) => {
      if (window.location.pathname === '/visual-meditation') {
        document.querySelector('svg use').setAttribute('fill', '#ffffff')
      }
      if (document.querySelector('.nav__head__profile__button svg use')) {
        document
          .querySelector('.nav__head__profile__button svg use')
          .setAttribute('fill', '#7131d8')
      }
    }
  })
}

const openedNav = (onClose, config) => (
  <div className="nav__head__inner">
    <Link to={'/'} onClick={() => onClose(false)}>
      <div className="nav__head__inner__logo">
        <img src={logo} alt="main-logo" />
      </div>
    </Link>
    <div className="nav__head__inner__close" onClick={() => onClose(false)}>
      <img src={close_button} alt="close" />
    </div>
  </div>
)

const closedNav = (
  onOpen,
  options,
  filter,
  filterHandler,
  onHandleSoundsList,
  onChange,
  translate
) => (
  <div className="nav__head">
    {options.backButton ? (
      <Link to={options.backButtonLink}>
        <div className="nav__head__back">
          <img src={arrow_back} alt="back" />
        </div>
      </Link>
    ) : (
      <div className="nav__head__burger" onClick={() => onOpen(true)}>
        <img
          src={burger}
          className="burger_image"
          onLoad={e => setConfig(e)}
          alt="burger"
        />
      </div>
    )}
    {options.searchInput && (
      <div className="nav__head__search">
        <div className="nav__head__search__input">
          <input
            type="text"
            placeholder={translate('filtering_options_searh_label', 'search')}
            onKeyUp={e => onChange(e.target.value)}
          />
        </div>
        <div className="nav__head__search__icon">
          <img src={search} alt="search-icon" />
        </div>
      </div>
    )}
    {options.filter && (
      <div className="nav__head__filter">
        <div
          onClick={() => filterHandler(true)}
          className="nav__head__filter__icon"
        >
          <img src={filterIcon} alt="filter-icon" />
        </div>
        {filter.visibility && <Filter />}
      </div>
    )}
    {options.soundButton && (
      <div className="nav__head__sound">
        <div
          onClick={() => onHandleSoundsList(true)}
          className="nav__head__sound__button"
        >
          <img src={soundButton} alt="sound-button" />
        </div>
      </div>
    )}
    {options.closeButton && (
      <div className="nav__head__close">
        <div
          onClick={() => onHandleSoundsList(false)}
          className="nav__head__sound__button"
        >
          <img src={close_button} alt="close-button" />
        </div>
      </div>
    )}
    {options.profileButton && (
      <div className="nav__head__profile">
        <Link to={'/profile'}>
          <div
            className="nav__head__profile__button"
            title="Eliga su instructor por favor"
          >
            <img src={person} alt="person-button" onLoad={e => setConfig(e)} />
          </div>
        </Link>
      </div>
    )}
  </div>
)

const itemsList = (list, onClose) => (
  <div className="nav__body__list">
    {list.map((item, i) => {
      return (
        <div key={i} className="nav__body__list__item">
          <Link to={item.link} key={i} onClick={() => onClose(false)}>
            <div className="nav__body__list__item__title">{item.title}</div>
            <div className="nav__body__list__item__icon">
              <img
                title={item.tooltip ? item.tooltip : item.title}
                src={item.icon}
                alt={'navigation-icon-' + i}
              />
            </div>
          </Link>
        </div>
      )
    })}
  </div>
)

class Navigation extends Component {
  state = {
    filterAnimationPrepared: false,
    itemsList: [],
    unsubscribe: undefined,
    langInitialized: false
  }
  componentDidMount() {
    this.setState(() => ({
      unsubscribe: store.subscribe(() => {
        if (
          this.state.langInitialized !== store.getState().langInitialized &&
          store.getState().lang
        ) {
          /* */
          //removing 'user guide' link from drawer
          let allowedLocalesToShowGuideLink = ['co', 'pe', 'es', 'mx'],
            allowedLocalesToShowDietsLink = ['mx'],
            { lang } = store.getState()
          if (!allowedLocalesToShowGuideLink.includes(lang)) {
            if (navItems.findIndex(itm => itm.link === '/guide') !== -1) {
              navItems.splice(
                navItems.findIndex(itm => itm.link === '/guide'),
                1
              )
            }
          }
          if (!allowedLocalesToShowDietsLink.includes(lang)) {
            if (navItems.findIndex(itm => itm.link === '/diets') !== -1) {
              navItems.splice(
                navItems.findIndex(itm => itm.link === '/diets'),
                1
              )
            }
          }
          /* */
          this.doTranslation(navItems)
        }
      })
    }))
  }

  componentWillUnmount() {
    if (this.state.unsubscribe) {
      this.state.unsubscribe()
    }
  }

  doTranslation = list => {
    let preparedList = list.map(item => {
      item.title = translate(item.key, item.fallback)
      return item
    })
    this.setState(() => ({ itemsList: preparedList, langInitialized: true }))
  }

  onHandleFilterVisibility = state => {
    this.props.actions.handleFilterVisibility(state)
    setTimeout(() => {
      this.setState({
        filterAnimationPrepared: !this.state.filterAnimationPrepared
      })
    }, 1000)
  }
  onHandleNavState = state => {
    state
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = '')

    this.props.actions.handleNavState(state)
  }
  onHandleSoundsList = state => {
    let config = {
      isDisplayed: true,
      isFixed: false,
      isOpened: false,
      options: {
        searchInput: false,
        filter: false,
        backButton: false,
        backButtonLink: '',
        soundButton: false,
        closeButton: true
      }
    }

    if (!state) {
      config.isFixed = true
      config.options.soundButton = true
      config.options.closeButton = false
      document.querySelector('svg use').setAttribute('fill', '#ffffff')
      document.querySelector('.nav__head__burger').style.display = 'block'
    } else {
      document.querySelector('svg use').setAttribute('fill', '#7131d8')
      document.querySelector('.nav__head__burger').style.display = 'none'
    }

    this.props.actions.handleHeaderConfig(config)
    this.props.actions.handleSoundsVisibility(state)
  }

  onInputChange = e => this.props.actions.searchYogaPoses(e)

  onSelectChange = ({ target }) => {
    const { actions, langInitialized } = this.props
    if (!langInitialized) return
    actions.initLang(false)
    actions.setPassedLang(target.value)
    actions.setAppLoading(true)
    actions
      .getYogaLang()
      .then(_ => {
        i18next.addResourceBundle(
          target.value,
          'translation',
          _.langConfig[target.value].translation
        )
        i18next.changeLanguage(target.value)
      })
      .finally(_ => {
        this.onHandleNavState(false)
        actions.setAppLoading(false)
        actions.initLang(true)
        this.doTranslation([...this.state.itemsList])
      })
  }

  render() {
    const { filter, actions, navConfig, lang: locale } = this.props

    return (
      <div
        className={
          'nav' +
          (navConfig.isOpened ? ' opened' : '') +
          (navConfig.isFixed ? ' fixed' : '')
        }
      >
        {navConfig.isOpened
          ? openedNav(this.onHandleNavState, navConfig)
          : closedNav(
              this.onHandleNavState,
              navConfig.options,
              filter,
              actions.handleFilterVisibility,
              this.onHandleSoundsList,
              this.onInputChange,
              translate
            )}

        <CSSTransition
          in={navConfig.isOpened}
          timeout={300}
          classNames="swipe-left"
          unmountOnExit
        >
          <div className="nav__body">
            {navConfig.isOpened && (
              <>
                {itemsList(this.state.itemsList, this.onHandleNavState)}
                
                {multiLangOptions[locale] && (
                  <SelectLang
                    onChange={this.onSelectChange}
                    options={multiLangOptions[locale]}
                  />
                )}
              </>
            )}
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default compose(
  connect(
    state => ({
      navConfig: state.navConfig,
      filter: state.filter,
      langConfig: state.langConfig,
      lang: state.lang,
      langInitialized: state.langInitialized
    }),
    dispatch => ({
      actions: bindActionCreators(actions, dispatch)
    })
  ),
  withRouter
)(Navigation)

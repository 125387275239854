import React from 'react'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as actions from '../../../store/actions'
import route from '../route'

const withLogic = WrappedComponent => class WithDietCategoriesLogic extends React.Component{
  state = {}
  componentDidMount(){
    switch(this.props.match.path){
      case '/diets':
          this.props.actions.handleHeaderConfig(route[2].headerConfig)
          break;
      case '/diets/:catId':
          this.props.actions.handleHeaderConfig(route[1].headerConfig)
          break;
      case '/diets/:catId/:postId':
          this.props.actions.handleHeaderConfig(route[0].headerConfig)
          break;
      default:
          this.props.actions.handleHeaderConfig(route[2].headerConfig)
    }
  }
  render(){
    return <WrappedComponent {...this.state} {...this.props} />
  }
}

const connectedToStore = connect(
  state => ({}),
  dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
)

export default compose(
  connectedToStore,
  withLogic
)
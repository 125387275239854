import { createStore, applyMiddleware } from "redux";
import promise from "redux-promise";
import { createLogger } from "redux-logger";

import reducer from "./reducer";

const logger = createLogger({
  collapsed: true
});

const state = {
  lang: "",
  passedLang: localStorage.getItem("passed-lang"),
  langConfig: null,
  isAuthorized: false,
  langInitialized: false,
  audioList: [],
  isLoading: false,
  posesList: [],
  levelsList: [],
  navConfig: {
    isDisplayed: true,
    isOpened: false,
    isFixed: false,
    options: {
      searchInput: false,
      filter: false,
      backButton: "",
      soundButton: false,
      closeButton: false,
      personButton: true,
      colorScheme: []
    }
  },
  meditation: {
    soundsVisibility: false
  },
  filter: {
    visibility: false
  },
  userInfo: {
    id: "guest",
    status: "not active"
  }
};

export default createStore(reducer, state, applyMiddleware(promise, logger));

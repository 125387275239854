import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loading from '../../../../loading'

import { translate } from '../../../../../utils/location'

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../store/actions";
import { getTheoryPost } from '../../../api'
import { history } from '../../../../../index'


import banner from "../../../../../assets/images/templates/meditation.png";
import arrow_back from "../../../../../assets/images/icons/arrow_back_white.svg";

import "../posts.scss";

class Post extends Component {
  state = {
    post: null,
    isLoaded: false
  }
  componentDidMount() {
    const { actions, match } = this.props

    actions.handleNavDisplaying(false);
    if (match.params['postId']) {
      getTheoryPost(match.params['postId'])
        .then(resp => {

          if(!resp.data.post_id) {
            console.log('post not found')
            history.push('/theory')
          }

          this.setState(_ => ({ post: resp.data, isLoaded: true }))
        })
        .catch(err => {
          history.push('/theory')
        })
    }else{
      history.push('/theory')
    }
  }
  render() {
    const { lang } = this.props
    const { post, isLoaded } = this.state
    return (
      isLoaded ? (
        <div className="post_single" style={{ direction: lang === 'iq' ? 'rtl' : 'ltr' }}>
          <div
            className="post_single__banner"
            style={{ backgroundImage: "url(" + (post.picture ? post.picture : banner) + ")" }}
          >
            <Link to={`/theory/${post.category_id}`}>
              <div className="post_single__banner__back">
                <img src={arrow_back} alt="" />
              </div>
            </Link>
            <img src={banner} style={{ visibility: "hidden" }} alt="" />
          </div>
          <div className="post_single__body">
            <div className="post_single__body__title">{post.title}</div>
            <div className="post_single__body__tag">{translate('sidebar_theory_title', '!theory')}</div>
            <div className="post_single__body__text" dangerouslySetInnerHTML={{ __html: post.text }} />
          </div>
        </div>
      ) : (
        <Loading />
      )
    );
  }
}

export default connect(
  state => ({
    navConfig: state.navConfig,
    lang: state.lang
  }),
  dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
)(Post);

import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions";

import { CSSTransition } from "react-transition-group";

import { getTheory } from "./api"

import Categories from "./Categories";
import Loading from "../loading";

import route from "./route"

class Theory extends Component {
  state = {
    loaded: false,
    prepared: false,
    list: []
  }
  componentDidMount() {
    this.props.actions.handleHeaderConfig(route[2].headerConfig);
    getTheory().then(resp => {
      this.setState(() => ({ list: resp, loaded: true }), () => {
        setTimeout(() => {
          this.setState({ prepared: true })
        }, 0)
      })
    })
  }
  render() {
    return this.state.loaded
      ? (
        <CSSTransition
          in={this.state.prepared}
          timeout={500}
          classNames="common"
          unmountOnExit
        >
          <Categories {...this.props} arr={this.state.list} />
        </CSSTransition>
      )
      : <Loading />
  }
}

export default connect(
  state => ({
    navConfig: state.navConfig
  }),
  dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
)(Theory);

import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../store/actions'
import { translate } from '../../utils/location'

import { history, cookies } from '../../index'

import { postLogin } from './api'
import { getUserInfo } from '../profile/api'

import route from './route'

import './auth.scss'

class Auth extends Component {
  state = {
    login: this.props.lang === 'pt' ? '351' : '',
    pass: '',
    message: {
      visibility: false,
      type: '',
      text: '1234',
    },
  }
  componentDidMount() {
    this.props.actions.handleHeaderConfig(route[0].headerConfig)
  }

  componentDidUpdate(prevProps) {
    const { lang } = this.props
    if (prevProps.lang !== lang) {
      if (lang) {
        this.setState(() => ({ login: lang === 'pt' ? '351' : '' }))
      }
    }
  }

  handleInput = ({ target }, input) => {
    this.setState(() => ({ [input]: target.value }))
  }

  handleMessage = (message) => {
    this.setState(() => ({ message }))
  }

  onSubmit = () => {
    const { login, pass } = this.state

    if (!login || !pass) return

    postLogin({ username: login, password: pass }).then((response) => {
      if (!response) {
        this.setState((_) => ({
          message: {
            visibility: true,
            type: 'error',
            text: 'Invalid login or password',
          },
        }))
      } else {
        cookies.set('username', login, {
          path: '/',
          expires: new Date(Date.now() + 86400 * 365),
        })
        if (response === 'account_status=canceled') {
          cookies.set('isUnsubscribed', 'true', {
            path: '/',
            expires: new Date(Date.now() + 86400 * 365),
          })
        }
        this.setState(
          (_) => ({
            message: {
              visibility: true,
              type: 'success',
              text: 'Success! You will be redirected now',
            },
          }),
          () => {
            this.props.actions.setAuth()
            getUserInfo()
              .then((userResponse) => {
                this.props.actions.setUserInfo(userResponse)
                setTimeout(() => history.push('/'), 1500)
              })
              .catch((err) => {
                console.log('ERROR during getting user info', err)
                setTimeout(() => history.push('/'), 1500)
              })
          }
        )
      }
    })
  }
  render() {
    const { lang } = this.props
    return (
      <div className="auth">
        <div className="auth__title">
          <h1>{translate('auth', 'Auth')}</h1>
          {lang === 'pt' && (
            <p>Por favor insira o seu número de telemóvel a começar por 351</p>
          )}
        </div>
        <div className="input">
          <input
            type="text"
            placeholder={translate('login', 'login')}
            defaultValue={this.state.login}
            onChange={(e) => this.handleInput(e, 'login')}
          />
        </div>
        <div className="input">
          <input
            type="password"
            placeholder={translate('password', 'password')}
            defaultValue={this.state.pass}
            onChange={(e) => this.handleInput(e, 'pass')}
          />
        </div>
        {this.state.message.visibility && (
          <p className={'auth__message ' + this.state.message.type}>
            {this.state.message.text}
          </p>
        )}
        <div className="auth__submit">
          <div className="btn" onClick={this.onSubmit}>
          {translate('login', 'login')}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    lang: state.lang,
  }),
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
  })
)(Auth)

import card1Banner from '../../../assets/images/templates/person1.png'
import card2Banner from '../../../assets/images/templates/person2.png'
import card3Banner from '../../../assets/images/templates/person3.png'
import card4Banner from '../../../assets/images/templates/person4.png'
import card5Banner from '../../../assets/images/templates/person5.png'
import card1SliderItm1 from '../../../assets/images/templates/slider-man1.png'
import card1SliderItm2 from '../../../assets/images/templates/slider-man2.png'
import card1SliderItm3 from '../../../assets/images/templates/slider-man3.png'
import card1SliderItm4 from '../../../assets/images/templates/slider-man4.png'

export default {
    cards: [
        {
            style: `linear-gradient(to top, #35a7e2 0%, #81dbf8 100%)`,
            banner: card1Banner,
            tagsItems: [
                {
                    text: 'beginner',
                    value: 'beginner'
                }
            ],
            descr: `Lorem ipsum dolor sit amet, consectetur 
            adipiscing elit, sed do
            eiusmod tempor incididunt 
            ut labore et dolore magna aliqua`,
            sliderBackColor: `#289edc`,
            sliderItems: [
                card1SliderItm1,
                card1SliderItm2,
                card1SliderItm3,
                card1SliderItm4,
                card1SliderItm4,
                card1SliderItm4,
            ]
        },
        {
            style: `linear-gradient(180deg, #ffc26d 0%, #ff9356 100%)`,
            banner: card2Banner,
            tagsItems: [
                {
                    text: 'beginner',
                    value: 'beginner'
                },
                {
                    text: 'intermediate',
                    value: 'intermediate'
                }
            ],
            descr: `Lorem ipsum dolor sit amet, consectetur 
            adipiscing elit, sed do
            eiusmod tempor incididunt 
            ut labore et dolore magna aliqua`,
            sliderBackColor: `#f38446`,
            sliderItems: [
                card1SliderItm1,
                card1SliderItm2,
                card1SliderItm3,
                card1SliderItm4,
                card1SliderItm4,
                card1SliderItm4,
            ]
        },
        {
            style: `linear-gradient(to top, #0d9a7b 0%, #9affc5 100%)`,
            banner: card3Banner,
            tagsItems: [
                {
                    text: 'intermediate',
                    value: 'intermediate'
                }
            ],
            descr: `Lorem ipsum dolor sit amet, consectetur 
            adipiscing elit, sed do
            eiusmod tempor incididunt 
            ut labore et dolore magna aliqua`,
            sliderBackColor: `#089374`,
            sliderItems: [
                card1SliderItm1,
                card1SliderItm2,
                card1SliderItm3,
                card1SliderItm4,
                card1SliderItm4,
                card1SliderItm4,
            ]
        },
        {
            style: `linear-gradient(to top, #c22b59 0%, #f099b7 100%)`,
            banner: card4Banner,
            tagsItems: [
                {
                    text: 'beginner',
                    value: 'beginner'
                }
            ],
            descr: `Lorem ipsum dolor sit amet, consectetur 
            adipiscing elit, sed do
            eiusmod tempor incididunt 
            ut labore et dolore magna aliqua`,
            sliderBackColor: `#b22953`,
            sliderItems: [
                card1SliderItm1,
                card1SliderItm2,
                card1SliderItm3,
                card1SliderItm4,
                card1SliderItm4,
                card1SliderItm4,
            ]
        },
        {
            style: `linear-gradient(to top, #4e23ae 0%, #bb87ff 100%)`,
            banner: card5Banner,
            tagsItems: [
                {
                    text: 'intermediate',
                    value: 'intermediate'
                },
                {
                    text: 'advanced',
                    value: 'advanced'
                }
            ],
            descr: `Lorem ipsum dolor sit amet, consectetur 
            adipiscing elit, sed do
            eiusmod tempor incididunt 
            ut labore et dolore magna aliqua`,
            sliderBackColor: `#4c24a3`,
            sliderItems: [
                card1SliderItm1,
                card1SliderItm2,
                card1SliderItm3,
                card1SliderItm4,
                card1SliderItm4,
                card1SliderItm4,
            ]
        },
    ]
}
export function getGradient(colors) {
  let result = "";
  colors.forEach((color, i) => {
    result =
      result +
      (color +
        ` ${(Math.floor(100 / colors.length)) * (i + 1)}%${
          i !== colors.length - 1 ? "," : ""
        } `);
  });
  result =
    !colors.length || colors.length === 1
      ? `linear-gradient(#0d9a7b 0%, #9affc5 100%)`
      : `linear-gradient(${result})`;

    return result
}



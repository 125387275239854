import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Link } from "react-router-dom";
import { translate } from "../../../utils/location"
import store from "../../../store"

import cardsList from "./cardsList";

import * as scroll from "react-scroll";
import * as actions from "../../../store/actions";

let Element = scroll.Element;

const list = cardsList =>
  cardsList.map((item, i) => (
    <Element key={i} name={"card-" + i}>
      <div
        className="presentation_cards__list__item card"
        style={{ backgroundImage: item.background }}
      >
        <div className="card__banner">
          <img src={item.banner} alt="card_banner" />
        </div>
        <div className="card__info">
          <div className="card__info__icon">
            <img src={item.icon} alt="icon" />
          </div>
          <div className="card__info__title">{translate(item.titleKey,item.titleFallback)}</div>
          <div className="card__info__descr">{translate(item.descrKey,item.descrFallback)}</div>
          <Link to={item.link}>
            <div
              className="card__info__button"
              style={{ backgroundColor: item.buttonColor }}
            >
              {translate(item.btnKey,item.btnFallback)}
            </div>
          </Link>
        </div>
      </div>
    </Element>
  ));

class PresentationCards extends Component {
  state = {
    unsubscribe: undefined,
    langInitialized: false,
    cards: cardsList
  }
  componentDidMount() {
    this.setState(() => ({
      unsubscribe: store.subscribe(() => {
        if (this.state.langInitialized !== store.getState().langInitialized) {
          this.doTranslation(cardsList, 'title', 'titleKey', 'titleFallback')
          this.doTranslation(cardsList, 'buttonLabel', 'btnKey', 'btnFallback')
          this.doTranslation(cardsList, 'description', 'descrKey', 'descrFallback')
        }
      })
    }))
  }

  componentWillUnmount() {
    if (this.state.unsubscribe) {
      this.state.unsubscribe()
    }
  }

  doTranslation = (list, dest, key, fallback) => {
    let preparedList = list.map(item => {
      item[dest] = translate(item[key], item[fallback])
      return item
    })
    this.setState(() => ({ cards: preparedList, langInitialized: true }))
  }

  render() {
    return (
      <div className="presentation_cards">
        <div 
          style={{direction: this.props.lang === 'iq' ? 'rtl' : 'ltr'}}
          className="presentation_cards__list">{list(this.state.cards)}</div>
      </div>
    );
  }
}

export default connect(
  state => ({
    lang: state.lang
  }),
  dispatch => ({actions: bindActionCreators(actions, dispatch)})
)(PresentationCards);

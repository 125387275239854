import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import * as actions from "../../store/actions";

import withApi from "./hoc/withApi";
import withLogic from "./hoc/withLogic";

import { CSSTransition } from "react-transition-group";

import "./poses.scss";

import Loading from "../loading";
import List from "./list";

function Poses(props) {
  return props.loaded ? (
    <CSSTransition timeout={500} in={props.prepared} classNames="common">
      <List {...props} />
    </CSSTransition>
  ) : (
    <Loading />
  );
}

const mapStateToProps = state => ({
  posesList: state.posesList,
  levelsList: state.levelsList,
  lang: state.lang
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withApi(''),
  withLogic
)(Poses);

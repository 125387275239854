import React, { Component } from "react";
import { scroller } from "react-scroll";

import { getGradient } from "../../../utils/themeSettings";
import { addToFavoriteList,removeFromFavoriteList, getFavorites } from "../api";
import route from "../route";
import favoriteRoute from '../../favorite/route'

export const getLevelClass = (id) => {
  switch (id){
    case '1': 
      return 'beginner'
    case '2': 
      return 'intermediate'
    case '3': 
      return 'advanced'
    default: 
      return 'beginner'
  }
}

export default function withLogic(WrappedComponent) {
  return class LogicContainer extends Component {
    state = {
      list: this.props.posesList,
      fullscreen: false
    };

    componentDidMount() {
      const { actions } = this.props
      console.log('props',this.props)
      actions.handleHeaderConfig(route[0].headerConfig);
      window.addEventListener('popstate',this.onWindowHistoryPopState) 
    }

    componentDidUpdate(prevProps){
      if(prevProps.posesList !== this.props.posesList){
        this.handlePoses(this.props.posesList, this.props.levelsList);
      }
    }

    onWindowHistoryPopState = () => {
      const { actions } = this.props
      actions.handleFilterVisibility(false)
      document.body.style.overflow = "auto"
    }

    componentWillReceiveProps() {
      this.handlePoses(this.props.posesList, this.props.levelsList);
    }

    getLevel = (id, levelsList) => {
      let filtered = levelsList.filter(level => level.lid === id);
      return filtered.length ? filtered[0].level_name.toLowerCase() : "";
    };

    handlePoses = (posesList, levelsList) => {
      this.setState(prev => ({
        list: posesList.map(item => {
          item.levelClassCSS = getLevelClass(item.level_id);
          item.gradient = getGradient(item.colors);
          item.level = this.getLevel(item.level_id, levelsList);
          item.fullscreen = false;
          item.yoga_b_visibility = false;
          item.yoga_c_visibility = false;
          !item.tags.includes(item.level) && item.tags.unshift(item.level);
          return item;
        })
      }));
      this.forceUpdate();
    };

    toggleFullscreen = (i, state) => {
      if(state){
        document.body.style.overflow = "hidden"
        window.history.pushState("", document.title, window.location.pathname + '?fullscreen')
      }else{
        document.body.style.overflow = "auto"
        window.history.pushState("", document.title, window.location.pathname + '')
      }

      let newArr = [...this.state.list];
      newArr[i].fullscreen = !newArr[i].fullscreen;
      
      this.setState(({ fullscreen }) => ({
        fullscreen: !fullscreen,
        list: newArr
      }));

      // setTimeout(() =>
      //   scroller.scrollTo("info", {
      //     smooth: true,
      //     containerId: "fixed"
      //   })
      // );
    };

    toggleInfoCharVisibility = (i, prop) => {
      let newArr = [...this.state.list];
      newArr[i][prop] = !newArr[i][prop];
      this.setState(() => ({ list: newArr }));
      return this.state.list[i]
    };

    toggleFavorite = (i, prop) => {
      let parent = document.querySelectorAll('.poses_list__item__set_favorite_btn')[i],
          visible = parent.querySelector('.visible'),
          hidden = parent.querySelector('.hidden'),
          elem = null;
      
      elem = this.toggleInfoCharVisibility(i,prop)
      
      if(elem.favorite){
        addToFavoriteList(elem.id).then(() => {
          getFavorites()
        })
      }else{
        removeFromFavoriteList(elem.id)
      }

      visible.classList.remove('visible')
      visible.classList.add('hidden')
      hidden.classList.add('visible')
      hidden.classList.remove('hidden')
    }

    render() {
      return (
        <WrappedComponent
          list={this.state.list}
          fullscreen={this.state.fullscreen}
          toggleFullscreen={this.toggleFullscreen}
          toggleInfoCharVisibility={this.toggleInfoCharVisibility}
          toggleFavorite={this.toggleFavorite}
          {...this.props}
        />
      );
    }
  };
}

import React from 'react';
import { Link } from "react-router-dom";
import { Flipped } from "react-flip-toolkit";

import favoriteBtn from "../../../assets/images/card_elems/heart_icon.svg";
import favoriteBtnFull from "../../../assets/images/card_elems/full_heart.svg";

export const Pose = ({ pose, i, toggleFullScreen, toggleFavorite, onLoadFavorite }) => (
  
    <Flipped key={i} flipId={`zoom-${i}`} transformOrigin="0 0">
      <div
        className={
          "poses_list__item " + (pose.fullscreen ? `zoom-${i} zoom` : "")
        }
        style={{ backgroundImage: pose.gradient }}
        key={i}
      >
        <div 
        onClick={() => toggleFavorite(i, 'favorite')}
        className="poses_list__item__set_favorite_btn">
          <img
            className={!pose.favorite ? 'visible' : 'hidden'}
            src={favoriteBtn}
            alt="set-favorite-button-full"
            onLoad = {e => onLoadFavorite(e.target, pose, i)}
          />
          <img
            className={pose.favorite ? 'visible' : 'hidden'}
            src={favoriteBtnFull}
            alt="set-favorite-button-full"
            onLoad = {e => onLoadFavorite(e.target, pose, i)}
          />
        </div>
        <div 
          onClick={e => toggleFullScreen(i, true)}
          className="poses_list__item__banner">
            <img src={pose.yoga_image_b} alt={"pose-" + i} />
        </div>
        <div 
          onClick={e => toggleFullScreen(i, true)}
          className="poses_list__item__title">{pose.yoga_title}</div>
        <div className="poses_list__item__subtitle">
          {pose.yoga_sanskrit_title}
        </div>
        <div className="poses_list__item__tags tags">
          {pose.tags.map(
            (tag, i) =>
              tag && (
                <div
                  key={i}
                  className={
                    "poses_list__item__tags__item tags__item " +
                    (!i ? pose.levelClassCSS : "other_tags")
                  }
                >
                  {!i 
                  ? (<Link to={`/poses?all_yoga_level_id=${pose.level_id}`}>{tag}</Link>)
                  : (<Link to={`/poses?all_yoga_tags=${tag}`}>{tag}</Link>)
                  }
                </div>
              )
          )}
        </div>
      </div>
    </Flipped>
  );
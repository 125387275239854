import Axios from "axios";

export function getTheory(){
    return Axios.get('/api.php?theory').then(resp => resp.data)
}

export function getTheoryCategory(id){
    return Axios.get(`/api.php?theory&category_id=${id}`).then(resp => resp)
}

export function getTheoryPost(id){
    return Axios.get(`/api.php?theory&post_id=${id}`).then(resp => resp)
}
import React, { Component } from "react";
import "../theory.scss";
import banner from "../../../assets/images/templates/meditation.png";
import { Link } from "react-router-dom";

import { translate } from "../../../utils/location";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../store/actions";

class Categories extends Component {
  componentDidMount() {
    this.props.actions.handleNavDisplaying(true);
    this.props.actions.changeBackBtnPath(this.props.location.pathname);
  }
  render() {
    const { lang, match } = this.props;
    return (
      <div className="theory">
        <div
          className="theory__cats"
          style={{ direction: lang === "iq" ? "rtl" : "ltr" }}
        >
          {this.props.arr.map((cat, ci) => (
            <div key={ci} className="theory__cats__item">
              <Link
                onClick={e => !cat.posts.length && e.preventDefault()}
                to={{
                  pathname: `${match.path}/${cat.category_id}`,
                  state: { posts: cat.posts, title: cat.category_title }
                }}
              >
                <div className="theory__cats__item__header">
                  <div className="theory__cats__item__header__title">
                    {cat.category_title}
                  </div>
                  <div className="theory__cats__item__header__tag">
                    {translate("theory_read_btn", "read")}
                  </div>
                </div>
              </Link>
              <div className="theory__cats__item__body">
                <div className="theory__cats__item__body__preview list">
                  {cat.posts.length ? (
                    cat.posts.map((itm, i) => (
                      <Link
                        key={i}
                        to={{
                          pathname: `${match.path}/${cat.category_id}/${itm.post_id}`,
                          state: { post: itm }
                        }}
                      >
                        <div className="list__item">
                          <div
                            className="list__item__banner"
                            style={{
                              backgroundImage:
                                "url(" +
                                (itm.image.length ? itm.image : banner) +
                                ")"
                            }}
                          />
                          <div className="list__item__info">
                            <div className="list__item__info__title">
                              {itm.title}
                            </div>
                            <div className="list__item__info__descr">
                              {itm.short_text}
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))
                  ) : (
                    <p>{translate('list_is_empty','list is empty')}</p>
                  )}
                </div>
              </div>
              <div className="theory__cats__item__devider" />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    navConfig: state.navConfig,
    lang: state.lang
  }),
  dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
)(Categories);

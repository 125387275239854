export default [
  {
    id: "arrow_back",
    handler: (img, svg, colors) => {
      let wrapper = document.querySelector(".app");
      svg
        .querySelector("use")
        .setAttribute(
          "fill",
          !wrapper.getAttribute("data-reversed")
            ? colors[0]
            : colors[colors.length - 1]
        );
    }
  },
  {
    id: "arrow_down",
    handler: (img, svg, colors) => {
      let wrapper = document.querySelector(".app");
      svg
        .querySelector("#button_background")
        .setAttribute(
          "fill",
          !wrapper.getAttribute("data-reversed")
            ? colors[0]
            : colors[colors.length - 1]
        );
      svg
        .querySelector("#button_arrow")
        .setAttribute(
          "fill",
          !wrapper.getAttribute("data-reversed")
            ? colors[colors.length - 1]
            : colors[0]
        );
    }
  },
  {
    id: "play_button",
    handler: (img, svg, colors) => {
      svg.querySelector("use").setAttribute("fill", colors[0]);
    }
  },
  {
    id: "overlay",
    handler: (element, colors) => {
      let wrapper = document.querySelector(".app");
      element.querySelector("#linearGradient-2").innerHTML = "";

      let colorsList = !wrapper.getAttribute("data-reversed")
        ? colors.reverse()
        : colors;
      colorsList.forEach((color, i) => {
        let stop = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "stop"
        );
        stop.setAttributeNS(null, "stop-color", color.toUpperCase());
        stop.setAttributeNS(null, "stop-opacity", "100%");
        stop.setAttributeNS(
          null,
          "offset",
          Math.floor(100 / colors.length) * (i + 1) + "%"
        );
        element.querySelector("#linearGradient-2").appendChild(stop);
      });
      wrapper.setAttribute("data-reversed", "true");
    },
    noneInject: true
  },
  {
    id: "nav-arrow",
    handler: (img, svg, colors) => {
      svg.querySelector("use").setAttribute("fill", colors[0]);
    }
  },
  {
    id: "repeat_button",
    handler: (img, svg, colors) => {
      svg.querySelector("path").setAttribute("fill", colors[0]);
      svg.querySelector("g").setAttribute("fill-opacity", "0.7");
    }
  }
];

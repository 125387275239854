import React from 'react'
import ReactDOM from 'react-dom'
import Axios from 'axios'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

import i18next from 'i18next'
import Cookies from 'universal-cookie'

import createBrowserHistory from 'history/createBrowserHistory'
import requestInterceptors from './utils/request'
import './index.css'
import App from './App'
import store from './store'
import * as actions from './store/actions'

import { getUserInfo, checkUnsubscribed } from './components/profile/api'
import multilangLocales from './multilang-locales'

export const history = createBrowserHistory()
export const cookies = new Cookies()
const urlParams = new URLSearchParams(window.location.search)
const useRoboto = () => {
  const style = document.createElement('style')
  const bodyNode = document.querySelector('body')
  style.innerHTML = `*{font-family: "Roboto Regular" !important;}`
  bodyNode.appendChild(style)
  bodyNode.setAttribute('style', 'font-family: "Roboto Regular"!important')
}

requestInterceptors()

getUserInfo()
  .then((response) => {
    const { locale, account_status } = response
    if (account_status === 'canceled') {
      cookies.set('isUnsubscribed', 'true', {
        path: '/',
        expires: new Date(Date.now() + 86400 * 365),
      })
    }
    let passedLang =
      multilangLocales.includes(locale) && urlParams.has('lang')
        ? urlParams.get('lang')
        : multilangLocales.includes(locale) &&
          localStorage.getItem('passed-lang')
        ? localStorage.getItem('passed-lang')
        : ''
    if (
      locale === 'iq' &&
      urlParams.get('source') &&
      urlParams.get('source') === 'cLq25wE7pxzjkDyV'
    ) {
      cookies.set('isProtected', 'true')
      store.dispatch(actions.setAuth())
    }
    store.dispatch(actions.setPassedLang(passedLang ? passedLang : locale))
    store.dispatch(actions.changeLang(locale))
    store.dispatch(actions.setUserInfo(response))
  })
  .catch((err) => console.log('error in getting user info ', err))
  .finally((_) => initApp())

if (cookies.get('isProtected')) {
  store.dispatch(actions.setAuth())
} else {
  if (urlParams.get('source') === 'dHcpDeur7EgKSLST') {
    cookies.set('isProtected', 'true')
    store.dispatch(actions.setAuth())
  } else {
    Axios.get('/subscription/authorize.php').then((resp) => {
      if (resp.data) {
        store.dispatch(actions.authLink(resp.data))
      } else {
        store.dispatch(actions.setAuth())
      }
    })
  }
}

if (urlParams.get('unsubscribe') === 'success') {
  cookies.set('isUnsubscribed', 'true', {
    path: '/',
    expires: new Date(Date.now() + 86400 * 365),
  })
}

function initApp() {
  store.dispatch(actions.getYogaLang()).then(() => {
    let allowedLocalesToShowGuideLink = ['co', 'pe', 'es', 'mx']
    const {
      langConfig,
      isAuthorized,
      lang: locale,
      passedLang,
    } = store.getState()

    console.log('TRANSLATION', langConfig)
    // console.log("locale", locale);

    if (locale === 'pt') {
      if (urlParams.get('login') === 'first') {
        cookies.set('username', 'some', {
          path: '/',
          expires: new Date(Date.now() + 86400 * 365),
        })
        store.dispatch(actions.setAuth())
        window.history.pushState({}, document.title, window.location.pathname)
      }
    }

    if (locale === 'mx') {
      useRoboto()
      if (!cookies.get('isProtected')) {
        Axios.get('/subscription/mx/checkStatus.php').then((unsubResponse) => {
          if (unsubResponse.data) {
            window.location.href =
              'https://portal.shop/index.php/club_yoga.html'
          }
        })
        if (!cookies.get('username')) {
          window.location.href = `${window.location.origin}/subscription/mx/identify.php${window.location.search}`
        }
      }
    }

    if (locale === 'iq' || locale === 'kw') {
      if (window.location.pathname === '/') {
        if (urlParams.get('msisdn') || urlParams.get('number')) {
          store.dispatch(actions.setAppLoading(true))

          Axios.get(
            `/subscription/${locale}/identify.php${window.location.search}`
          )
            .then((response) => {
              if (!response.data) {
                store.dispatch(actions.setAuth())
                window.history.pushState(
                  '',
                  document.title,
                  window.location.pathname + ''
                )
              } else {
                window.location.href = response.data
              }
              store.dispatch(actions.setAppLoading(false))
            })
            .catch((err) => {
              console.log(err)
              store.dispatch(actions.setAppLoading(false))
            })
        }
      }
    }

    if (locale === 'pk') {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl')
    }

    if (locale === 'gr') {
      useRoboto()
      if (urlParams.has('msisdn') || urlParams.has('MSISDN')) {
        const msisdn = urlParams.get('msisdn') || urlParams.get('MSISDN')
        let formData = new FormData()
        formData.append('msisdn', msisdn.toLowerCase())
        formData.append('type', 'client')
        Axios({
          method: 'POST',
          url: '/subscription/gr/checkUser.php',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        }).then((r) => {
          store.dispatch(actions.setAuth())
        })
      }
      checkUnsubscribed().then((unsubResponse) => {
        if (unsubResponse.data) {
          history.push('/')
          cookies.remove('username')
          store.dispatch(actions.setAuth(false))
          Axios.get('/subscription/authorize.php').then((authResponse) => {
            if (authResponse.data) {
              store.dispatch(actions.authLink(authResponse.data))
            } else {
              store.dispatch(actions.setAuth())
            }
          })
        }
      })
    }

    if (locale === 'es') {
      if (cookies.get('redirect-point')) {
        if (isAuthorized) {
          history.push('/' + cookies.get('redirect-point'))
        }
        cookies.remove('redirect-point')
      }
    }

    if (locale === 'co') {
      if (!cookies.get('identified') && !cookies.get('isProtected')) {
        store.dispatch(actions.setAppLoading(true))
        if (urlParams.has('identified') && cookies.get('auth')) {
          cookies.set('identified', 'true')
          cookies.remove('auth')
          store.dispatch(actions.setAppLoading(false))
        } else {
          window.location.href = `${window.location.origin}/subscription/co/identify.php${window.location.search}`
          return
        }
      }
    }

    if (window.location.pathname === '/guide') {
      if (!allowedLocalesToShowGuideLink.includes(locale)) {
        history.push('/')
      }
    }

    i18next
      .init({
        lng: passedLang,
        resources: langConfig,
        initImmediate: false,
      })
      .finally((_) => {
        store.dispatch(actions.initLang())
      })
  })
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
)

import { translate } from "../../utils/location"

import yoga from '../../assets/images/icons/yoga_icon.svg'
import heart from '../../assets/images/icons/heart_icon.svg'
import star from '../../assets/images/icons/star_icon.svg'
import lotus from '../../assets/images/icons/lotus_icon.svg'
import music from '../../assets/images/icons/music_icon.svg'
import book from '../../assets/images/icons/book_icon.svg'
import person from '../../assets/images/icons/person_icon.svg'
import guide from '../../assets/images/icons/book.svg'
import apple from '../../assets/images/icons/apple_diet.svg'

export default [
  {
    title: translate('sidebar_poses_title','Yoga poses'),
    link: "/poses",
    icon: yoga,
    key: 'sidebar_poses_title',
    fallback: 'Yoga poses',
    tooltip: ''
  },
  {
    title: translate('sidebar_favorite_title','Favorite poses'),
    link: "/favorite",
    icon: heart,
    key: 'sidebar_favorite_title',
    fallback: 'Favorite poses',
    tooltip: '',
  },
  {
    title: translate('sidebar_programs_title','Yoga programs'),
    link: "/programs",
    icon: star,
    key: 'sidebar_programs_title',
    fallback: 'Yoga programs',
    tooltip: '',
  },
  {
    title: translate('sidebar_meditation_title','Meditation'),
    link: "/visual-meditation",
    icon: lotus,
    key: 'sidebar_meditation_title',
    fallback: 'Meditation',
    tooltip: '',
  },
  {
    title: translate('sidebar_sounds_title','Sounds'),
    link: "/audio-meditation",
    icon: music,
    key: 'sidebar_sounds_title',
    fallback: 'Sounds',
    tooltip: '',
  },
  {
    title: translate('sidebar_theory_title','Theory'),
    link: "/theory",
    icon: book,
    key: 'sidebar_theory_title',
    fallback: 'Theory',
    tooltip: '',
  },
  {
    title: 'Guide',
    link: "/guide",
    icon: guide,
    key: 'Guía del usuario',
    fallback: 'Guide',
    tooltip: '',
  },
  {
    title: 'Diets',
    link: "/diets",
    icon: apple,
    key: 'sidebar_diets_title',
    fallback: 'Diets',
    tooltip: 'Diets',
  },
  {
    title: translate('sidebar_profile_title','Profile'),
    link: "/profile",
    icon: person,
    key: 'sidebar_profile_title',
    fallback: 'Profile',
    tooltip: 'Eliga su instructor por favor',
  }
];

import React from 'react'
import { Route } from 'react-router-dom'

import { history } from './index'
import { cookies } from './index'

import store from './store'

import multilangLocales from './multilang-locales'

import Pose from './components/pose/route'
import Poses from './components/poses/route'
import Favorite from './components/favorite/route'
import Programs from './components/programs/route'
import Theory from './components/theory/route'
import Meditation from './components/meditation/route'
import Profile from './components/profile/route'
import Unsubscribe from './components/unsubscribe/route'
import Content from './components/content/route'
import Welcome from './components/welcome/route'
import Diets from './components/diets/route'

const routes = [
  ...Pose,
  ...Poses,
  ...Favorite,
  ...Theory,
  ...Meditation,
  ...Unsubscribe,
  ...Profile,
  ...Welcome,
  ...Content,
  ...Diets,
  ...Programs,
]

const manualAuthLocales = ['pt', 'fr', 'be', 'dz']
const classicAuthLocales = [
  'es',
  'pl',
  'se',
  'en',
  'iq',
  'gr',
  'sw',
  'za',
  'at',
  'kw',
  'mx',
  // 'dz',
  'ps',
  'jo',
]

function checkIfAllowed(props, route) {
  const { locale, passedLang } = route
  const redirectUrl = multilangLocales.includes(locale)
    ? withLang(store.getState().authLink)
    : store.getState().authLink

  function withLang(url = '') {
    const hasQuery = url.indexOf('?') !== -1
    return `${url}${`${hasQuery ? '&' : '?'}lang=${passedLang}`}`
  }

  if (classicAuthLocales.includes(locale)) {
    if (
      !store.getState().isAuthorized &&
      route.path !== '/unsubscribe' &&
      route.path !== '/welcome' &&
      !route.path.includes('/content/')
    ) {
      if (locale === 'es') {
        cookies.set('redirect-point', route.path.substr(1), {
          expires: new Date(Date.now() + 86400 * 365),
        })
      }

      window.location.href = redirectUrl
      return
    }
  }

  if (manualAuthLocales.includes(locale)) {
    if (!store.getState().isAuthorized) {
      if (route.path !== '/profile') {
        window.location.href = redirectUrl
      } else {
        history.push('/auth')
      }
    } else {
      if (cookies.get('isUnsubscribed') && route.path !== '/profile') {
        window.location.href = 'http://yogaforyou.club/bh-land/'
      }
    }
  }

  if (locale === 'id' || locale === 'bh') {
    if (!store.getState().isAuthorized) {
      history.push('/auth')
    }
  }

  if (locale === 'bh') {
    if (cookies.get('isUnsubscribed') && route.path !== '/profile') {
      history.push('/auth')
    }
  }

  return <route.component {...props} {...route} />
}

export const RouteWithSubRoutes = (route) => {
  return (
    <Route
      exact={route.isExact}
      path={route.path}
      render={(props) => checkIfAllowed({ ...props }, route)}
    />
  )
}

export { routes }

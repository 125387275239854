export default {
  dz: [
    {
      value: 'dz',
      label: 'عربى'
    },
    {
      value: 'fr',
      label: 'Française'
    }
  ],
  ps: [
    {
      value: 'ps',
      label: 'عربى'
    },
    {
      value: 'uk',
      label: 'English'
    }
  ],
  be: [
    {
      value: 'be',
      label: 'Dutch'
    },
    {
      value: 'fr',
      label: 'Française'
    }
  ]
}

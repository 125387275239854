import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { translate } from "../../utils/location";

import withApi from "../poses/hoc/withApi"
import withLogic from "../poses/hoc/withLogic"

import List from "../poses/list"

import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import * as actions from "../../store/actions";

import route from './route'
import './favorite.scss'

import banner from "../../assets/images/templates/home/b3.png"

function Favorite(props) {
    return (
        // <div className="favorite">
        //     <div className="favorite__banner">
        //         <img src={banner} alt="banner"/>
        //     </div>
        //     <div className="favorite__title">
        //         <h1>{translate('auth_required_title','please authorize')}</h1>
        //     </div>
        //     <div className="favorite__descr">
        //         {translate('auth_required_description','Please login to your account to enjoy our yoga portal')}
        //     </div>
        //     <Link to="/profile">
        //         <button className="favorite__btn btn">
        //             {translate('auth_required_profile_btn','Profile page')}
        //         </button>
        //     </Link>
        // </div>
        <List {...props} />
    )
}

export default compose(
    connect(
        state => ({
            posesList: state.posesList,
            levelsList: state.levelsList
        }),
        dispatch => ({
            actions: bindActionCreators(actions, dispatch)
        })
    ),
    withApi('/api.php?favorites'),
    withLogic
)(Favorite);

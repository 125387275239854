import * as actions from "./actions";

export default function reducer(state, action) {
  switch (action.type) {
    case actions.GET_YOGA_ITEMS:
      return {
        ...state,
        levelsList: action.levelsList
      };
    case actions.GET_YOGA_AUDIO:
      return {
        ...state,
        audioList: action.audioList
      };
    case actions.GET_YOGA_POSES:
      return {
        ...state,
        posesList: action.posesList
      };
    case actions.GET_YOGA_LEVELS:
      return {
        ...state,
        levelsList: action.levelsList
      };
    case actions.SEARCH_YOGA_POSES:
      return {
        ...state,
        posesList: action.posesList
      };
    case actions.CHANGE_LANG:
      return {
        ...state,
        lang: action.lang
      };
    case actions.GET_LANG:
      return {
        ...state,
        langConfig: action.langConfig
      };
    case actions.INIT_LANG:
      return {
        ...state,
        langInitialized: action.langInitialized
      };
    case actions.HANDLE_NAV_STATE:
      return {
        ...state,
        navConfig: {
          ...state.navConfig,
          isOpened: action.navState
        }
      };
    case actions.HANDLE_NAV_DISPLAYING:
      return {
        ...state,
        navConfig: {
          ...state.navConfig,
          isDisplayed: action.navDisplaying
        }
      };
    case actions.HANDLE_NAV_CONFIG:
      return {
        ...state,
        navConfig: action.navConfig
      };
    case actions.HANDLE_NAV_FIXED:
      return {
        ...state,
        navConfig: {
          ...state.navConfig,
          isFixed: action.navFixed
        }
      };
    case actions.HANDLE_FILTER_VISIBILITY:
      return {
        ...state,
        filter: {
          visibility: action.filterVisibility
        }
      };
    case actions.HANDLE_SOUNDS_VISIBILITY:
      return {
        ...state,
        meditation: {
          ...state.meditaion,
          soundsVisibility: action.soundsVisibility
        }
      };
    case actions.CHANGE_BACK_BTN_PATH:
      return {
        ...state,
        navConfig: {
          ...state.navConfig,
          options: {
            ...state.navConfig.options,
            backButtonLink: action.path
          }
        }
      };
    case actions.SET_AUTH:
      return {
        ...state,
        isAuthorized: action.isAuthorized
      };
    case actions.SET_AUTH_LINK:
      return {
        ...state,
        authLink: action.authLink
      };
    case actions.SET_USER_INFO:
      return {
        ...state,
        userInfo: action.userInfo
      };
    case actions.SET_APP_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case actions.SET_PASSED_LANG:
      return {
        ...state,
        passedLang: action.passedLang
      };
    default:
      return state;
  }
}

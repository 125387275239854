import React, { Component } from "react";

import SVGInject from "@iconfu/svg-inject";
import { history } from "../../../index";
import { getGradient } from "../../../utils/themeSettings";

import elems from "../PoseCard/cardSVGElems";

export default function withLogic(WrappedComponent) {
  return class WithLogic extends Component {
    state = {
      data: this.props.data,
      list: [],
      programMode: false,
      paused: true,
      ended: false,
      video: null
    };
    componentDidMount() {
      this.props.actions.handleNavDisplaying(false);
    }
    componentWillReceiveProps(nextProps) {
      if (nextProps.data) {
        let response = { ...nextProps.data };
        if (window.location.hash) {
          response.gradient = getGradient(this.props.location.state.colors);
          response.colors = this.props.location.state.colors;
          this.setState({
            data: response,
            list: nextProps.location.state.list,
            programMode: true
          });
        } else {
          response.gradient = getGradient(response.colors);
          this.setState(prev => ({ data: response }));
        }
        setTimeout(() => {
          this.setState(() => ({
            video: document.querySelector("#pose_video")
          }));
        });
      }
    }

    hasNext = (id, direction) => {
      let list = this.state.list;
      let index = list.indexOf(list.filter(itm => itm.id === id)[0]);
      return direction === "next"
        ? index + 1 <= list.length - 1
        : index - 1 >= 0;
    };

    navigate = (id, direction) => {
      let list = this.state.list;
      let index = list.indexOf(list.filter(itm => itm.id === id)[0]);
      let resultIndex = index;
      if (direction === "next") {
        if (index + 1 <= list.length - 1) {
          resultIndex = index + 1;
        }
      } else {
        if (index - 1 >= 0) {
          resultIndex = index - 1;
        }
      }
      let config = {
        pathname: `/poses/${list[resultIndex].id}`,
        state: this.props.location.state,
        hash: this.props.location.hash
      };
      history.push(config);
    };

    handleColorScheme = (id, target) => {
      let elem = elems.filter(itm => itm.id === id)[0];
      let { colors } = this.state.data;
      if (elem.hasOwnProperty("noneInject")) {
        elem.handler(target, colors);
      } else {
        SVGInject(target, {
          afterInject: (img, svg) => elem.handler(img, svg, colors)
        });
      }
    };

    togglePlay = () => {
      let poseVideo = this.state.video;
      if (poseVideo.paused) {
        poseVideo.play();
        this.setState(() => ({ paused: false, ended: false }));
      } else {
        poseVideo.pause();
        this.setState(() => ({ paused: true, ended: false }));
      }
    };

    onEnd = () => {
      this.setState({ paused: true, ended: true });
    };

    render() {
      return (
        <WrappedComponent
          hasNext={this.hasNext}
          navigate={this.navigate}
          handleColorScheme={this.handleColorScheme}
          togglePlay={this.togglePlay}
          onEnd={this.onEnd}
          {...this.props}
          {...this.state}
        />
      );
    }
  };
}

import Axios from "axios"

export function getYogaLevels(){
    return Axios.get(`/api.php?level_list`)
}

export function addToFavoriteList(id){
    return Axios.get(`/api.php?add_favorite=${id}`)
}

export function removeFromFavoriteList(id){
    return Axios.get(`/api.php?remove_favorite=${id}`)
}

export function getFavorites(){
    return Axios.get(`/api.php?favorites`)
}
import React from "react";
import { Flipped } from "react-flip-toolkit";
import { Link } from "react-router-dom";
import { Element } from "react-scroll";

import { translate } from "../../../utils/location";

import favoriteBtn from "../../../assets/images/card_elems/heart_icon.svg";
import favoriteBtnFull from "../../../assets/images/card_elems/full_heart.svg";
import arrow_back from "../../../assets/images/icons/arrow_back_white.svg";

export const FullScreenPose = ({
  lang,
  pose,
  i,
  toggleFullScreen,
  beforeEnter,
  handleVisibility,
  toggleFavorite,
  onLoadFavorite
}) => (
  <Flipped key={i} flipId={`zoom-${i}`} transformOrigin="0 0">
    <div
      className={`poses_list__item zoom zoom-${i}`}
      style={{ backgroundImage: pose.gradient }}
      id="fixed"
    >
      <div className="poses_list__item__nav zoom__nav">
        <div
          onClick={e => toggleFullScreen(i, false)}
          className="poses_list__item__back zoom__nav__back"
        >
          <img src={arrow_back} alt="back" />
        </div>
        <div
          onClick={() => toggleFavorite(i, "favorite")}
          className="poses_list__item__set_favorite_btn zoom__nav__fav"
        >
          <img
            className={!pose.favorite ? "visible" : "hidden"}
            src={favoriteBtn}
            alt="set-favorite-button-full"
            onLoad={e => onLoadFavorite(e.target, pose, i)}
          />
          <img
            className={pose.favorite ? "visible" : "hidden"}
            src={favoriteBtnFull}
            alt="set-favorite-button-full"
            onLoad={e => onLoadFavorite(e.target, pose, i)}
          />
        </div>
      </div>
      <div className="poses_list__item__banner">
        <img src={pose.yoga_image_b} alt={"pose-" + i} />
      </div>
      <div
        style={{ direction: lang === "iq" ? "rtl" : "ltr" }}
        className="poses_list__item__title"
      >
        {pose.yoga_title}
      </div>
      <div className="poses_list__item__subtitle">
        {pose.yoga_sanskrit_title}
      </div>
      <div className="poses_list__item__tags tags">
        {pose.tags.map(
          (tag, i) =>
            tag && (
              <div
                key={i}
                className={
                  "poses_list__item__tags__item tags__item " +
                  (!i ? pose.levelClassCSS : "other_tags")
                }
              >
                {!i ? (
                  <Link to={`/poses?all_yoga_level_id=${pose.level_id}`}>
                    {tag}
                  </Link>
                ) : (
                  <Link to={`/poses?all_yoga_tags=${tag}`}>{tag}</Link>
                )}
              </div>
            )
        )}
      </div>
      <Link onClick={beforeEnter} to={`/poses/${pose.id}`}>
        <div
          className="poses_list__item__btn btn"
          style={{ backgroundColor: pose.colors[0] }}
        >
          {translate("poses_list_start_training_btn", "Start Training")}
        </div>
      </Link>
      <Element name={"info"}>
        <div className="dropdown_list">
          <div className="dropdown_list__itm">
            <div
              style={{
                paddingRight: lang === "iq" ? 35 : "auto",
                direction: lang === "iq" ? "rtl" : "ltr"
              }}
              className={
                "dropdown_list__itm__label " +
                (pose.yoga_b_visibility ? "opened" : "")
              }
              onClick={() => handleVisibility(i, "yoga_b_visibility")}
            >
              {translate("poses_list_benefits_title", "Benefits")}
            </div>
            {pose.yoga_b_visibility && (
              <div className="dropdown_list__itm__hidden">
                {pose.yoga_benefits.map((itm, i) => (
                  <div
                    key={i}
                    style={{
                      paddingRight: lang === "iq" ? 35 : "auto",
                      direction: lang === "iq" ? "rtl" : "ltr"
                    }}
                    className={
                      "dropdown_list__itm__hidden__item " +
                      (lang === "iq" ? "rtl" : "")
                    }
                  >
                    {itm}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="dropdown_list__itm">
            <div
              style={{
                paddingRight: lang === "iq" ? 35 : "auto",
                direction: lang === "iq" ? "rtl" : "ltr"
              }}
              className={
                "dropdown_list__itm__label " +
                (pose.yoga_c_visibility ? "opened" : "")
              }
              onClick={() => handleVisibility(i, "yoga_c_visibility")}
            >
              {translate("poses_list_cautions_title", "Cautions")}
            </div>
            {pose.yoga_c_visibility && (
              <div className="dropdown_list__itm__hidden">
                {pose.yoga_cautions.map((itm, i) => (
                  <div
                    key={i}
                    style={{
                      paddingRight: lang === "iq" ? 35 : "auto",
                      direction: lang === "iq" ? "rtl" : "ltr"
                    }}
                    className={
                      "dropdown_list__itm__hidden__item " +
                      (lang === "iq" ? "rtl" : "")
                    }
                  >
                    {itm}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {lang === "at" && (
          <div className="links">
            <p>
              <Link to="/content/at/faq">Über Ihr Abo</Link>
            </p>
            <p>
              <Link to="/content/at/privacy-policy">
                Datenschutz-bestimmungen
              </Link>
            </p>
            <p>
              <Link to="/content/at/Reference-to-right-of-withdrawal">
                Hinweis auf Rücktrittsrecht
              </Link>
            </p>
            <p>
              <Link to="/content/at/Terms-of-Use">Nutzungsbedingungen</Link>
            </p>
            <p>
              <a href="https://sfbtech.org/at-imprint.html" target="_blank">
                Imprint
              </a>
            </p>
          </div>
        )}
      </Element>
    </div>
  </Flipped>
);

import UnsubsPage from './index'

export default [
  {
    path: '/unsubscribe',
    component: UnsubsPage,
    isExact: false,
    headerConfig: {
      isDisplayed: false
    }
  }
]
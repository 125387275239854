import React from "react";
import assets from "./cardsList";
import { Link } from "react-router-dom"
import { getLevelClass } from "../../poses/hoc/withLogic"

const sliderList = (list, colors) => (
  <div className="cards__item__slider">
    {list.map((item, i) => (
      <div key={i}
        className="cards__item__slider__item"
        style={{ backgroundColor: colors[colors.length - 1] }}>
        <img src={item.yoga_image_b} alt={"slider-item-" + i} />
      </div>
    ))}
    <div className="cards__item__slider__item" />
  </div>
);

const tagsList = list => (
  <div className="cards__item__info__tags tags">
    {list.map((item, i) => (
      <div key={i} className={"cards__item__info__tags__item tags__item " + getLevelClass(item.level_id)}>
        {item.item}
      </div>
    ))}
  </div>
);

export default function ProgramCards({ data }) {
  return (
    <div className="program_cards cards">
      {data.map((item, i) => (
        <Link key={i} to={{ 
            pathname: `/poses/${item.yoga_list[0].id}`, 
            hash: `#${item.cid}`, 
            state: { 
              list: item.yoga_list, 
              colors: item.category_colors 
            } 
          }}>
          <div
            key={i}
            className="cards__item"
            style={{ backgroundImage: item.gradient }}
          >
            <div className="cards__item__banner">
              <img src={assets.cards[i].banner} alt="card-banner" />
              {/* <img src={item.category_image_b} alt="card-banner" /> */}
            </div>
            <div className="cards__item__info">
              <div className="cards__item__info__title">{item.category_name}</div>
              {tagsList([{item: item.level, level_id: item.category_level_id}])}
              <div className="cards__item__info__descr">
                {item.category_desc}
              </div>
            </div>
            {sliderList(item.yoga_list, item.category_colors)}
          </div>
        </Link>
      ))}
    </div>
  )
}

import Diets from './index'
import Category from './containers/SingleCategory'
import Post from './containers/SinglePost'

export default [
    {
        path: '/diets/:catId/:postId',
        component: Post,
        isExact: true,
        headerConfig: {
          isFixed: true
        }
    },
    {
        path: '/diets/:catId',
        component: Category,
        isExact: true,
        headerConfig: {
            isDisplayed: true,
            isFixed: false,
            isOpened: false,
            options: {
                searchInput: false,
                filter: false,
                backButton: true,
                backButtonLink: '/diets',
                soundButton: false,
                closeButton: false,
            }
        }
    },
    {
        path: '/diets',
        component: Diets,
        isExact: false,
        headerConfig: {
            isDisplayed: true,
            isFixed: false,
            isOpened: false,
            options: {
                searchInput: false,
                filter: false,
                backButton: false,
                soundButton: false,
                closeButton: false,
                profileButton: true
            }
        }
    },
]
import React from "react";

import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import * as actions from "../../store/actions";

import withApi from "./hoc/withApi";
import withLogic from "./hoc/withLogic";
import FilterOptions from "./FilterOptions";

import "./filter.scss";

export const LEVELS_LABEL = "levels";
export const TAGS_LABEL = "tags";

function Filter(props) {
  return (
    <FilterOptions
      onOptionChoose={props.onOptionChoose}
      onSubmitFilter={props.onSubmitFilter}
      {...props}
    />
  );
}

export default compose(
  connect(
    state => ({}),
    dispatch => ({
      actions: bindActionCreators(actions, dispatch)
    })
  ),
  withApi,
  withLogic
)(Filter);

import Poses from './index'

export default [
    {
        path: '/poses',
        component: Poses,
        isExact: false,
        headerConfig: {
            isDisplayed: true,
            isFixed: false,
            isOpened: false,
            options: {
                searchInput: true,
                filter: true,
                backButton: false,
                backButtonLink: '',
                soundButton: false,
                closeButton: false,
                profileButton: false
            }
        }
    }
]
import ContentPage from './index'

export default [
    {
        path: '/content/:locale/:fileName',
        component: ContentPage,
        isExact: false,
        headerConfig: {
            isDisplayed: true,
            isFixed: false,
            isOpened: false,
            options: {
                searchInput: false,
                filter: false,
                backButton: false,
                backButtonLink: '',
                soundButton: false,
                closeButton: false
            }
        }
    }
]
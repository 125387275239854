import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import { Switch, withRouter, Route } from 'react-router-dom'
import Home from './components/home'
import Auth from './components/auth'
import Guide from './components/guide'
import { RouteWithSubRoutes, routes } from './router'
import Loading from './components/loading'

import Navigation from './components/navigation'
import './App.scss'
import * as actions from './store/actions'

import withFooter from './hoc/withFooter'
import withUser from './hoc/withUser/index'

class App extends Component {
  render() {
    const {
      locale,
      passedLang,
      navConfig,
      isLoading,
      isAuthorized,
    } = this.props
    return (
      <main className="app">
        <header className="app__header">
          {navConfig.isDisplayed && <Navigation />}
        </header>

        <section>
          {isLoading ? (
            <Loading />
          ) : (
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/auth" component={Auth} />
              <Route path="/guide" component={Guide} />
              {routes.map((route, i) => (
                <RouteWithSubRoutes
                  key={i}
                  {...route}
                  locale={locale}
                  passedLang={passedLang}
                />
              ))}
            </Switch>
          )}
        </section>
      </main>
    )
  }
}

export default compose(
  withUser,
  withRouter,
  withFooter,

  connect(
    (state) => ({
      navConfig: state.navConfig,
      isLoading: state.isLoading,
      locale: state.lang,
      passedLang: state.passedLang,
      isAuthorized: state.isAuthorized,
    }),
    (dispatch) => ({
      actions: bindActionCreators(actions, dispatch),
    })
  )
)(App)

import React from "react";
import { Link } from "react-router-dom";
import {compose} from 'redux'
import {connect} from 'react-redux'

import withApi from './hoc/withApi'
import withLoading from '../../hoc/withLoading'
import withLogic from '../../hoc/withLogic'
import { translate } from '../../../../utils/location'

import banner from '../../../../assets/images/templates/meditation.png'
import arrow_back from "../../../../assets/images/icons/arrow_back_white.svg";

class SinglePost extends React.Component {
  render() {
    const {post} = this.props
    return (
      post && 
      <div
        className="post_single"
        // style={{ direction: lang === "iq" ? "rtl" : "ltr" }}
      >
        <div
          className="post_single__banner"
          style={{
            backgroundImage:
              "url(" + (post.picture ? post.picture : banner) + ")"
          }}
        >
          <Link to={`/diets/${post.category_id}`}>
            <div className="post_single__banner__back">
              <img src={arrow_back} alt="" />
            </div>
          </Link>
          <img src={banner} style={{ visibility: "hidden" }} alt="" />
        </div>
        <div className="post_single__body">
          <div className="post_single__body__title">{post.title}</div>
          <div className="post_single__body__tag">
            {translate("sidebar_theory_title", "!theory")}
          </div>
          <div
            className="post_single__body__text"
            dangerouslySetInnerHTML={{ __html: post.text }}
          />
        </div>
      </div>
    );
  }
}
export default compose(
  connect(null,null),
  withApi,
  withLoading,
  withLogic
)(SinglePost)

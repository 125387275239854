import React, { Component } from "react";
import lottie from "lottie-web";

import "./loading.scss";

export default class Loading extends Component {
  componentDidMount() {
    lottie.loadAnimation({
      container: document.querySelector(".loading__img"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/loading.json"
    });
  }
  render() {
    return (
      <div className="loading">
        <div className="loading__img"></div>
      </div>
    );
  }
}

import React from "react";
import Loading from "../loading";
import { CSSTransition } from "react-transition-group";

import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import * as actions from "../../store/actions";

import PoseCard from "./PoseCard";
import withApi from "./hoc/withApi";
import withLogic from "./hoc/withLogic";
import "./pose.scss";

function Pose(props) {
  // console.log('loaded:',props.loaded)
  return props.loaded ? (
    <CSSTransition
      in={props.loaded}
      timeout={500}
      classNames="common"
      unmountOnExit
    >
      <PoseCard {...props} />
    </CSSTransition>
  ) : (
    <Loading />
  );
}

const mapStateToProps = state => ({
  lang: state.lang
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withApi,
  withLogic
)(Pose);

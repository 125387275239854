import React from "react";
import PropTypes from "prop-types";
import Loading from "../../loading";
import { CSSTransition } from "react-transition-group";

export default WrappedComponent =>
  class WithLoading extends React.Component {
    state = { 
      loaded: this.props.loaded,
      prepared: false
    };
    static propTypes = {
      loaded: PropTypes.bool.isRequired
    };
    setIsLoaded = loaded => this.setState(_ => ({ loaded }), _ => {
      setTimeout(_ => this.setState(state => ({ prepared: state.loaded })))
    });
    componentDidUpdate(prevProps) {
      if (prevProps.loaded !== this.props.loaded) {
        this.setIsLoaded(this.props.loaded);
      }
    }
    render() {
      const { loaded, prepared } = this.state;
      return loaded ? (
        <CSSTransition
          in={prepared}
          timeout={1000}
          classNames="common"
          unmountOnExit
        >
          <WrappedComponent {...this.state} {...this.props} />
        </CSSTransition>
      ) : (
        <Loading />
      );
    }
  };

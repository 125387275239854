import React, { Component } from "react";

import { getTags, getLevels } from "../api";

export default function withApi(WrappedComponent) {
  return class WithApi extends Component {
    state = {
      levels: [],
      tags: []
    };
    componentDidMount() {
      Promise.all([getLevels(), getTags()]).then(response => {
        //преобразование объекта в массив
        let tags = []
        for(let key in response[1]){
          tags.push(response[1][key])
        }
        this.setState(() => ({ levels: response[0], tags }));
      });
    }

    render() {
      return <WrappedComponent {...this.props} {...this.state} />;
    }
  };
}

import React, { Component } from "react";

const withApi = (url = '') => (WrappedComponent) => {
  return class WithApi extends Component {
    state = {
      posesList: [],
      levelsList: [],
      loaded: false,
      prepared: false
    };
    componentDidMount() {
      this.loadData()
    }

    componentDidUpdate(prevProps){
      if(prevProps.posesList !== this.props.posesList){
        this.setState(_ => ({
          posesList: this.props.posesList
        }))
      }
    }

    componentWillReceiveProps(nextProps) {
      if(nextProps.location.search !== this.props.location.search){
        this.setState(
          () => ({
            loaded: false,
            prepared: false
          }),
          () => this.loadData()
        );
      }
    }
    updateState = ( posesList = [], levelsList = [] ) => {
      this.setState(
        () => ({
          posesList: posesList,
          levelsList: levelsList,
          loaded: true
        }),
        () => setTimeout(() => this.setState(prev => ({ prepared: true })))
      );
    };

    loadData = () => {
      const { getYogaPoses, getYogaLevels } = this.props.actions;
      Promise.all([
          getYogaPoses(window.location.search.replace('?','&'),url), 
          getYogaLevels()
        ])
          .then((data) =>{
            console.log('data loaded: ',data[0].posesList)
            this.updateState(data[0].posesList, data[1].levelsList)
          }
      );
    }

    render() {
      return <WrappedComponent {...this.props} {...this.state} />;
    }
  };
}

export default withApi

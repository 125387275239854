import React, { Component } from "react";
import { getYogaItem } from "../api";

export default function withApi(WrappedComponent) {
  return class WithApi extends Component {
    state = {
      data: null,
      loaded: false
    };

    componentDidMount() {
      this.loadPose(this.props.match.params.id);
    }

    componentWillReceiveProps(nextProps) {
      this.loadPose(nextProps.match.params.id);
    }

    toggleLoaded = state => {
      this.setState(() => ({ loaded: state }));
    };

    loadPose = id => {
      this.setState(() => ({ loaded: false }),() => {
        getYogaItem(id).then(response =>
          this.setState(prev => ({ data: response, loaded: true }))
        );
      });
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          toggleLoaded={this.toggleLoaded}
        />
      );
    }
  };
}

import React from 'react'
import { Link } from 'react-router-dom'

import './modal.scss'

export default class Modal extends React.Component {
  state = {
    isOpen: false
  }
  componentDidMount(){
    this.setState(()=>({isOpen: this.props.isOpen}))
  }
  componentDidUpdate(prevProps,prevState){
    if(prevProps.isOpen !== this.props.isOpen || 
        prevState.isOpen !== this.props.isOpen){
      this.setState(()=>({isOpen: this.props.isOpen}))
    }
    this.state.isOpen 
      ? document.body.style.overflow = 'hidden' 
      : document.body.style.overflow = 'auto' 
  }
  onSubmit = () => {
    this.props.onClose()
  }
  render() {
    return ( 
      <div className="modal" style={{display: this.state.isOpen ? 'flex' : 'none'}}>
        <div className="modal__overlay"></div>
        <div className="modal-block">
          <div 
            onClick={this.onSubmit}
            className="modal-block__close">
            X
          </div>
          {this.props.children}
        </div>
      </div>
     )
  }
}
import React, { Component, createElement } from 'react'
import { Link } from 'react-router-dom'
import { translate } from '../utils/location'
import store from '../store'
import { history, cookies } from '../index'
import { connect } from 'react-redux'
import { compose } from 'redux'

import multilangLocales from '../multilang-locales'

const pagesWithoutFooter = [
  '/profile',
  '/favorite',
  '/visual-meditation',
  '/unsubscribe',
]

const localesWithoutFooter = ['upstream', 'u', 'iq', 'co']

const withFooter = (WrappedComponent) =>
  class WithFooter extends Component {
    state = {
      subscribeText: 'Subscribe',
      unsubscribeText: 'Unsubscribe',
      termsText: 'Terms and Conditions',
      copyrightText: 'All rights reserved',
    }

    setTexts({ subscribeText, unsubscribeText, termsText }) {
      this.setState((state) => ({
        ...state,
        subscribeText,
        unsubscribeText,
        termsText,
      }))
    }

    componentDidUpdate(prevProps, state) {
      if (prevProps.langInitialized !== this.props.langInitialized) {
        this.setTexts({
          subscribeText: translate('subscribe', state.subscribeText),
          unsubscribeText: translate(
            'profile_unsubscribe_btn',
            state.unsubscribeText
          ),
          termsText: translate('profile_terms_link_text', state.termsText),
          copyrightText: translate('copyright', state.copyrightText),
        })
      }
    }

    onSubmit = () => {
      const { lang } = this.props
      if (lang === 'iq') {
        if (!store.getState().isAuthorized) {
          window.location.href = 'http://yogaforyou.club/iq/iq-land/index.php'
        } else {
          window.location.href =
            window.location.origin + '/subscription/unsubscribe.php'
        }
        return
      }
      if (lang === 'it') {
        window.open(
          window.location.origin + '/subscription/it/land/unsubscribe.html'
        )
        return
      }
      if (lang === 'mx') {
        window.location.href =
          window.location.origin + '/subscription/mx/land/unsubscribe.html'
        return
      }

      window.location.href =
        window.location.origin + '/subscription/unsubscribe.php'
    }

    render() {
      const { lang, passedLang, isAuthorized } = this.props
      const {
        termsText,
        subscribeText,
        unsubscribeText,
        copyrightText,
      } = this.state
      return (
        <div className="wrapped_component">
          <div className="wrapped_component__content">
            <WrappedComponent {...this.props} />
          </div>
          {!pagesWithoutFooter.includes(window.location.pathname) &&
            !localesWithoutFooter.includes(lang) && (
              <footer>
                <FooterContent {...this.props} {...this.state} />

                <p>
                  <a href={`/terms.php?source=app&lang=${passedLang}`}>
                    {termsText}
                  </a>
                </p>
                <p>{`© 2020 Yoga For You. ${copyrightText}.`}</p>
              </footer>
            )}
        </div>
      )
    }
  }

function FooterContent(props) {
  const { lang = 'en', passedLang, subscribeLink: authLink } = props
  
  const unsubLink = window.location.origin + '/subscription/unsubscribe.php'
  const subscribeLink = multilangLocales.includes(lang)
    ? withLang(authLink)
    : authLink

  function withLang(url = '') {
    const hasQuery = url.indexOf('?') !== -1
    return `${url}${`${hasQuery ? '&' : '?'}lang=${passedLang}`}`
  }

  const locaseCases = {
    // id: IDLocale,
    gr: GRLocale,
    at: ATLocale,
    mx: MXLocale,
    it: ITLocale,
    pt: PTLocale,
    ps: ({ isAuthorized, ...props }) =>
      isAuthorized ? null : (
        <CommonCases {...props} isAuthorized={isAuthorized} />
      ),
  }

  if (locaseCases[lang])
    return createElement(locaseCases[lang], {
      ...props,
      unsubLink,
      subscribeLink,
    })

  return (
    <CommonCases
      {...props}
      unsubLink={unsubLink}
      subscribeLink={subscribeLink}
    />
  )
}

function PTLocale({}) {
  return (
    <p style={{ textAlign: 'center' }}>
      <Link to="/profile">Gestão de conta</Link>
    </p>
  )
}

function GRLocale() {
  return (
    <>
      <p>
        <Link target="_blank" to="/content/gr/faq">
          ΣΥΧΝΕΣ ΕΡΩΤΗΣΕΙΣ
        </Link>
      </p>
      <p>
        <Link target="_blank" to="/content/gr/privacy-policy">
          ΠΟΛΙΤΙΚΗ ΑΠΟΡΡΗΤΟΥ
        </Link>
      </p>
      <p>
        <Link target="_blank" to="/content/gr/contact-us">
          EΠΙΚΟΙΝΩΝΙΑ
        </Link>
      </p>
      <p>
        <Link target="_blank" to="/content/gr/terms">
          ΟΡΟΙ ΧΡΗΣΗΣ
        </Link>
      </p>
    </>
  )
}

function ATLocale({ onSubmit, unsubscribeText }) {
  return (
    <>
      <p>
        <Link to="/content/at/faq">Über Ihr Abo</Link>
      </p>
      <p>
        <Link to="/content/at/privacy-policy">Datenschutz-bestimmungen</Link>
      </p>
      <p>
        <Link to="/content/at/Reference-to-right-of-withdrawal">
          Hinweis auf Rücktrittsrecht
        </Link>
      </p>
      <p>
        <Link to="/content/at/Terms-of-Use">Nutzungsbedingungen</Link>
      </p>
      <p>
        <a href="https://sfbtech.org/at-imprint.html" target="_blank">
          Imprint
        </a>
      </p>
      <button onClick={onSubmit} className="btn" style={{ marginTop: 15 }}>
        {unsubscribeText}
      </button>
    </>
  )
}

function MXLocale({ onSubmit, subscribeText, termsText }) {
  return (
    <>
      <p>
        <Link to="/content/mx/privacy-policy">Política de privacidad</Link>
      </p>
      <p>
        <a href="/terms.php?source=app">{termsText}</a>
      </p>
      <p>Número 800 de atención a clientes: 800-0300712</p>
      <button onClick={onSubmit} className="btn" style={{ marginTop: 15 }}>
        {subscribeText}
      </button>
    </>
  )
}

function ITLocale({ onSubmit, unsubscribeText }) {
  return (
    <>
      <button onClick={onSubmit} className="btn">
        {unsubscribeText}
      </button>
      <p>
        <a href="/subscription/it/land/privacy.html" target="_blank">
          Politica sulla riservatezza
        </a>
      </p>
    </>
  )
}

function CommonCases({
  isAuthorized,
  unsubLink,
  subscribeLink,
  unsubscribeText,
  subscribeText,
}) {
  return (
    <a
      href={isAuthorized ? unsubLink : subscribeLink}
      rel="noopener noreferrer"
      className="btn"
    >
      {isAuthorized ? unsubscribeText : subscribeText}
    </a>
  )
}

const connectedToStore = connect(
  (state) => ({
    lang: state.lang,
    passedLang: state.passedLang,
    subscribeLink: state.authLink,
    isAuthorized: state.isAuthorized,
    dictionary: state.langConfig,
    langInitialized: state.langInitialized,
  }),
  null
)

export default compose(connectedToStore, withFooter)

import React from 'react';
import submit from "../../../assets/images/submit_button.svg";

import {LEVELS_LABEL,TAGS_LABEL} from '../index'

export default function FilterOptions(props){
    const {
        levels,
        tags,
        onOptionChoose,
        onSubmitFilter,
        translate
    } = props
    return (
        <div className="filter">
        <div className="filter__scroll">
          <div className="filter__sort_by level">
            <div className="filter__sort_by__title">{translate('filtering_options_by_level_label','Sort by your level')}</div>
            <div className="level__list">
              {levels.map((level, i) => (
                <div
                  key={i}
                  className="level__list__item"
                  onClick={() => onOptionChoose(i,LEVELS_LABEL)}
                >
                  <div className="level__list__item__circle">
                    <div
                      style={{
                        backgroundImage: level.active ? level.color : ""
                      }}
                      className={
                        "level__list__item__circle__color " +
                        (level.active ? "active" : "")
                      }
                    >
                      <div className="level__list__item__circle__mask">
                        <div className="level__list__item__circle__thumb">
                          <img src={level.level_image_b} alt="thumb" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="level__list__item__label">{level.level_name}</div>
                </div>
              ))}
            </div>
            <div className="filter__sort_by__devider" />
          </div>
          <div className="filter__sort_by type">
            <div className="filter__sort_by__title">{translate('filtering_options_by_type_label','Sort by type')}</div>
            <div className="type__list">
              {tags && tags.map((type, i) => (
                <div
                  key={i}
                  className={
                    "type__list__item " + (type.active ? "active" : "")
                  }
                  onClick={() => {
                    onOptionChoose(i,TAGS_LABEL)
                  }}
                >
                  {type.label}
                </div>
              ))}
            </div>
            <div className="filter__sort_by__devider" />
          </div>
          <div className="filter__submit">
            <div onClick={onSubmitFilter} className="filter__submit__btn">
              <img src={submit} alt="submit_button" />
            </div>
          </div>
        </div>
      </div>
    )
}
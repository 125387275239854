import Programs from './index'

export default [
    {
        path: '/programs',
        component: Programs,
        isExact: true,
        headerConfig: {
            isDisplayed: true,
            isFixed: false,
            isOpened: false,
            options: {
                searchInput: false,
                filter: false,
                backButton: false,
                backButtonLink: '',
                soundButton: false,
                closeButton: false,
                profileButton: true
            }
        }
    }
]
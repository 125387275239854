import React from "react";
import { Link } from "react-router-dom";
import {compose} from 'redux'
import {connect} from 'react-redux'

import withApi from './hoc/withApi'
import withLoading from '../../hoc/withLoading'
import withLogic from '../../hoc/withLogic'

import banner from '../../../../assets/images/templates/meditation.png'

class SingleCategory extends React.Component {
  render() {
    const { category } = this.props
    return (
      <div className="posts">
        <div className="posts__title">{category && category.category_title}</div>
        <div className="posts__list">
          {category && category.posts.map((post, i) => (
            <Link
              key={i} to={`/diets/${category.category_id}/${post.post_id}`}>
              <div key={i} className="posts__list__item post_card">
                <div
                  className="post_card__banner"
                  style={{
                    backgroundImage:
                      "url(" + (post.image ? post.image : banner) + ")"
                  }}
                />
                <div className="post_card__info">
                  <div className="post_card__info__title">{post.title}</div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(state => ({ state }),
  null),
  withApi,
  withLoading,
  withLogic
)(SingleCategory)

import React, { Component } from "react";

import { translate } from "../../../utils/location"

import { history } from "../../../index";
import config from "../config";


export const LEVELS_LABEL = "levels";
export const TAGS_LABEL = "tags";

export const active = label => label + "_active";

const filtersList = [
  {
    query: "all_yoga_level_id",
    stateLabel: LEVELS_LABEL
  },
  {
    query: "all_yoga_tags",
    stateLabel: TAGS_LABEL
  }
];

export default function withLogic(WrappedComponent) {
  return class WithLogic extends Component {
    state = {
      [LEVELS_LABEL]: [],
      [TAGS_LABEL]: [],
      [active(LEVELS_LABEL)]: [],
      [active(TAGS_LABEL)]: []
    };

    componentDidMount() {
      const urlParams = new URLSearchParams(window.location.search);
      filtersList.forEach(filter => {
        let params = urlParams.get(filter.query);
        params &&
          this.setState(() => ({
            [active(filter.stateLabel)]: params.split(",")
          }), () => console.log(this.state));
      });
      this.updateState(this.props);
    }

    componentWillReceiveProps(nextProps) {
      this.updateState(nextProps);
    }

    updateState = ({ levels, tags }) => {
      this.setState(() => ({
        levels: levels.map(level => {
          level.color = config.sort.byLevel.filter(
            fitm => fitm.lid === level.lid
          )[0].color;
          level.active = this.state.levels_active.includes(level.lid) ? true : false;
          return level;
        }),
        tags: tags.map(tag => {
          let label = tag;
          let newItem = {
            label,
            lid: label,
            active: this.state.tags_active.includes(label) ? true : false
          };
          return newItem;
        })
      }));
    };

    onOptionChoose = (i, filterName) => {
      let filterArr = [...this.state[filterName]];
      let activeFilterArr = [...this.state[active(filterName)]];

      let item = filterArr[i];
      item.active = !item.active;

      item.active
        ? activeFilterArr.push(item.lid)
        : activeFilterArr.indexOf(item.lid) >= 0 &&
        activeFilterArr.splice(activeFilterArr.indexOf(item.lid), 1);

      this.setState(
        () => ({
          [filterName]: filterArr,
          [active(filterName)]: activeFilterArr
        }),
        () => {
          console.log(this.state);
        }
      );
    };

    getFilterOptions = (by, list, devider) => {
      let res = list.join(",");
      return res.length ? `${devider}${by}=${res}` : "";
    };

    onSubmitFilter = () => {
      let filterString = "";
      filtersList.forEach(filter => {
        if (this.state[active(filter.stateLabel)].length) {
          filterString += this.getFilterOptions(
            filter.query,
            this.state[active(filter.stateLabel)],
            "&"
          );
        }
      });
      let locationFilterString = filterString.replace("&", "?");
      if (filterString) {
        history.location.search = ''
        filtersList.forEach(filter => {
          this.setState(() => ({ [active(filter.stateLabel)]: [] }))
        })
        this.props.actions.getYogaPoses(filterString).then(() => {
          this.props.actions.handleFilterVisibility(false);
          history.push({
            pathname: window.location.pathname,
            search: locationFilterString
          });
        });
      } else {
        this.props.actions.getYogaPoses(filterString).then(() => {
          this.props.actions.handleFilterVisibility(false);
          history.push({
            pathname: window.location.pathname,
            search: ''
          });
        })
      }
    };

    render() {
      return (
        <WrappedComponent
          onOptionChoose={this.onOptionChoose}
          onSubmitFilter={this.onSubmitFilter}
          translate={translate}
          {...this.props}
          {...this.state}
        />
      );
    }
  };
}

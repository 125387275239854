import store from '../store'
import Axios from 'axios'

Axios.interceptors.request.use(
  config => {
    const target = '/api.php'
    const accountRequest = `${target}?account`
    const { passedLang, lang: locale } = store.getState()

    if (
      config.url.includes(target) &&
      config.url !== accountRequest &&
      passedLang
    ) {
      passedLang !== locale && (config.url += `&new_lang=${passedLang}`)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export default () => {
  Axios.interceptors.response.use(
    response => {
      if (
        response.config.url.includes('level_list') ||
        response.config.url.includes('all_yoga') ||
        response.config.url.includes('search=') ||
        response.config.url.includes('yoga_id=') ||
        response.config.url.includes('cat_list') ||
        response.config.url.includes('cat_id') ||
        response.config.url.includes('favorites')
      ) {
        response = response.data.YOGA_APP
      }
      return response
    },
    error => {
      return Promise.reject(error)
    }
  )
}

import React from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import withApi from './hoc/withApi'

import Categories from '../theory/Categories'
import withLoading from './hoc/withLoading'
import withLogic from './hoc/withLogic'

class Diets extends React.Component{
  render(){
    return <Categories {...this.props} arr={this.props.diets} />
  }
}

export default compose(
  connect(
    null,
    null
  ),
  withApi,
  withLoading,
  withLogic
)(Diets);

import Axios from "axios";

export const GET_YOGA_ITEMS = "GET_YOGA_ITEMS";
export const GET_YOGA_AUDIO = "GET_YOGA_AUDIO";
export const GET_YOGA_POSES = "GET_YOGA_POSES";
export const GET_YOGA_LEVELS = "GET_YOGA_LEVELS";

export const CHANGE_LANG = "CHANGE_LANG";
export const INIT_LANG = "INIT_LANG";
export const HANDLE_NAV_STATE = "HANDLE_NAV_STATE";
export const HANDLE_NAV_FIXED = "HANDLE_NAV_FIXED";
export const HANDLE_NAV_DISPLAYING = "HANDLE_NAV_DISPLAYING";
export const HANDLE_NAV_CONFIG = "HANDLE_NAV_CONFIG";
export const HANDLE_FILTER_VISIBILITY = "HANDLE_FILTER_VISIBILITY";
export const HANDLE_SOUNDS_VISIBILITY = "HANDLE_SOUNDS_VISIBILITY";
export const CHANGE_BACK_BTN_PATH = "CHANGE_BACK_BTN_PATH";
export const SEARCH_YOGA_POSES = "SEARCH_YOGA_POSES";
export const GET_LANG = "GET_LANG";
export const SET_AUTH = "SET_AUTH";
export const SET_AUTH_LINK = "SET_AUTH_LINK";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_APP_LOADING = "SET_APP_LOADING";
export const SET_PASSED_LANG = "SET_PASSED_LANG";

export const setPassedLang = lang => {
  localStorage.setItem("passed-lang", lang);
  return { type: SET_PASSED_LANG, passedLang: lang };
};

export function setAppLoading(state) {
  return {
    type: SET_APP_LOADING,
    isLoading: state
  };
}

export function setUserInfo(userInfo) {
  return {
    type: SET_USER_INFO,
    userInfo
  };
}

export function setAuth(state = true) {
  return {
    type: SET_AUTH,
    isAuthorized: state
  };
}

export function authLink(authLink) {
  return {
    type: SET_AUTH_LINK,
    authLink
  };
}

export function getYogaLang() {
  return Axios.get(`/api.php?lang`).then(resp => ({
    type: GET_LANG,
    langConfig: resp.data
  }));
}

export function getYogaItems() {
  return Axios.get(`/api.php?level_list`).then(data => ({
    type: GET_YOGA_ITEMS,
    levelsList: data
  }));
}

export function getYogaAudio() {
  return Axios.get(`/api.php?meditation_categories`).then(data => ({
    type: GET_YOGA_AUDIO,
    audioList: [...data.data.categories.Aqua, ...data.data.categories.Terra]
  }));
}

export function getYogaPoses(options, url) {
  if (!url) {
    let hash = window.location.hash;
    if (hash.substring(hash.indexOf("#") + 1)) {
      return Axios.get(
        `/api.php?cat_id=${hash.substring(hash.indexOf("#") + 1)}`
      ).then(data => ({
        type: GET_YOGA_POSES,
        posesList: data[0][0].yoga_list
      }));
    } else {
      return Axios.get(`/api.php?all_yoga${options || ""}`).then(data => ({
        type: GET_YOGA_POSES,
        posesList: data
      }));
    }
  }
  return Axios.get(url).then(data => ({
    type: GET_YOGA_POSES,
    posesList: data
  }));
}

export function getYogaLevels() {
  return Axios.get(`/api.php?level_list`).then(data => ({
    type: GET_YOGA_LEVELS,
    levelsList: data
  }));
}

export function searchYogaPoses(query) {
  return Axios.get(`/api.php?search=${query}`).then(data => ({
    type: SEARCH_YOGA_POSES,
    posesList: data
  }));
}

export function changeLang(lang) {
  return {
    type: CHANGE_LANG,
    lang
  };
}

export function initLang(state = true) {
  return {
    type: INIT_LANG,
    langInitialized: state
  };
}

export function handleNavDisplaying(displayingState) {
  return {
    type: HANDLE_NAV_DISPLAYING,
    navDisplaying: displayingState
  };
}

export function handleNavState(state) {
  return {
    type: HANDLE_NAV_STATE,
    navState: state
  };
}

export function handleNavFixed(state) {
  return {
    type: HANDLE_NAV_FIXED,
    navFixed: state
  };
}

export function handleHeaderConfig(config) {
  return {
    type: HANDLE_NAV_CONFIG,
    navConfig: config
  };
}

export function handleFilterVisibility(visibilityState) {
  return {
    type: HANDLE_FILTER_VISIBILITY,
    filterVisibility: visibilityState
  };
}

export function handleSoundsVisibility(visibilityState) {
  return {
    type: HANDLE_SOUNDS_VISIBILITY,
    soundsVisibility: visibilityState
  };
}

export function changeBackBtnPath(path) {
  return {
    type: CHANGE_BACK_BTN_PATH,
    path
  };
}

import Home from './index'

export default [
    {
        path: '/home',
        component: Home,
        isExact: false,
        headerConfig: {
            isDisplayed: true,
            isFixed: false,
            isOpened: false,
            options: {
                searchInput: false,
                filter: false,
                backButton: false,
                backButtonLink: '',
                soundButton: false,
                closeButton: false,
                profileButton: true
            }
        }
    }
]
import Theory from './index'
import Category from './Categories/PostsContainer'
import Post from './Categories/PostsContainer/Post'

export default [
    {
        path: '/theory/:catId/:postId',
        component: Post,
        isExact: true
    },
    {
        path: '/theory/:catId',
        component: Category,
        isExact: true,
        headerConfig: {
            isDisplayed: true,
            isFixed: false,
            isOpened: false,
            options: {
                searchInput: true,
                filter: false,
                backButton: true,
                backButtonLink: '/theory',
                soundButton: false,
                closeButton: false,
            }
        }
    },
    {
        path: '/theory',
        component: Theory,
        isExact: false,
        headerConfig: {
            isDisplayed: true,
            isFixed: false,
            isOpened: false,
            options: {
                searchInput: false,
                filter: false,
                backButton: false,
                soundButton: false,
                closeButton: false,
                profileButton: true
            }
        }
    },
]
import React from 'react'
import { getPost } from '../../../api'

export default WrappedComponent => class WithCategoryApi extends React.Component{
  state = {
    post: null,
    loaded: false
  }

  async componentDidMount(){
    const { match } = this.props
    if(match.params && Object.entries(match.params).length){
      try{
        const postResponse = await getPost(match.params['postId'])
        this.setPost(postResponse)
        this.setIsLoaded(true)
        // console.log('resp',postResponse)
      } catch {
        console.log('err during post fetch')
      }
    }else{
     this.props.history.push('/diets') 
    }
  }
  setIsLoaded = loaded => this.setState(_ => ({ loaded }))
  setPost = post => this.setState(_ => ({ post }))

  render(){
    return <WrappedComponent {...this.state} {...this.props} />
  }
}
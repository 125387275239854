import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import store from '../../store'
import * as actions from "../../store/actions";

import Modal from '../modal'

import route from './route'

import unsubBanner from '../../assets/images/banner-unsub.png'
import unsubBannerPt from '../../assets/images/land1.jpg'

import './unsubscribe.scss'

class UnsubscribePage extends Component {
  state = {
    isModalOpen: false,
    locale: 'es'
  }
  componentDidMount() {
    store.subscribe(() => {
      if (store.getState().langInitialized && store.getState().lang) {
        this.setState(_ => ({ locale: store.getState().lang }))
      }
    })
    if (!!new URLSearchParams(window.location.search).get('success')) {
      this.handleModal(true)
    }
    this.props.actions.handleHeaderConfig(route[0].headerConfig);
  }
  handleModal = (state) => {
    this.setState(() => ({ isModalOpen: state }))
  }
  render() {
    const { locale } = this.state
    return (
      <div className="unsub">
        <div className="unsub-head">
          <div className="unsub-head__banner">
            <img src={locale === 'es' ? unsubBanner : unsubBannerPt} alt="banner" />
          </div>
          <div className="unsub-head__text-under-banner">
            {locale === 'es'
              ? '¿Quieres darte de baja del servicio? Haz un solo click abajo'
              : 'Deseja cancelar a inscrição do serviço? Faça um único clique abaixo'}
          </div>
        </div>
        <div className="unsub-buttons">
          <div className="unsub-buttons__submit" onClick={() => window.location.href = window.location.origin + '/subscription/unsubscribe.php?confirm'}>
            {locale === 'es'
              ? 'Cancelar Suscripción'
              : 'cancelar a assinatura'}
          </div>
          <div
            onClick={() => window.location.href = window.location.origin + '/'}
            className="unsub-buttons__cancel">
            {
              locale === 'es'
                ? 'Volver al Servicio'
                : 'Retornar ao serviço'
            }

          </div>
        </div>
        <div className="unsub-info">
          {
            locale === 'es'
              ? (`Si quieres gestionar tu suscripción, puedes ponerte en contacto 
              con nosotros a través de Teléfono de Atención al cliente: ` )
              : (`Se você quiser gerenciar sua assinatura, entre em contato conosco através do Atendimento ao Cliente. Telefone: `)
          }
          <strong>914 89 29 12</strong>
        </div>
        <div className="unsub-mail">
          {locale === 'es' ? 'es.clients@yogaforyou.club  ' : 'pt.support@sfbtech.org'}
        </div>
        <div className="unsub-terms">
          <a href="#" onClick={() => window.location.href = window.location.origin + '/terms.php'}>Términos y Condiciones</a>
        </div>
        <Modal
          isOpen={this.state.isModalOpen}
          onClose={() => this.handleModal(false)}
        >
          {
            locale === 'es' ? (
              <div>
                <div className="modal-block__text">
                  <p>La página https://es.yogaforyou.club dice:</p>
                  <p>Cancelación realizada:</p>
                  <p>Te confirmamos que acabas de darte de baja de Yoga For You.</p>
                  <p>Puedes volver a disfrutar del servicio en cualquier momento entrando
                en https://es.yogaforyou.club</p>
                </div>
                <div className="modal-block__submit" onClick={() => this.handleModal(false)}>
                  Aceptar
                </div>
              </div>
            ) : (
                <div>
                  <div className="modal-block__text">
                    <p>A página https://es.yogaforyou.club diz:</p>
                    <p>Cancelamento é feito:</p>
                    <p>Confirmamos que você acabou de descadastrar do Yoga For You.</p>
                    <p>Você pode voltar para desfrutar do serviço a qualquer momento, digitando https://es.yogaforyou.club </p>
                  </div>
                  <div className="modal-block__submit" onClick={() => this.handleModal(false)}>
                    Aceitar
                  </div>
                </div>
              )
          }
        </Modal>
      </div>
    )
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
)(UnsubscribePage);

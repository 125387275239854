import React, { Component } from "react";

import List from "./List";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../store/actions";

const arr = [1, 2, 3, 4, 5];

class PostsList extends Component {
  componentDidMount() {
    console.log("POSTSLIST: ", this.props);
    this.props.actions.handleHeaderConfig({
      options: {
        ...this.props.navConfig.options,
        filter: false,
        searchInput: true,
        backButton: true
      }
    });
    this.props.actions.handleNavDisplaying(true);
  }
  render() {
    return <List {...this.props} arr={arr} />;
  }
}

export default connect(
  state => ({
    navConfig: state.navConfig
  }),
  dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
)(PostsList);

import React, { Component } from "react";
import "../meditation.scss";

import {translate} from "../../../utils/location";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../store/actions";
import { CSSTransition } from "react-transition-group";
import Loading from "../../loading";

import route from "../route";

class MeditationAudio extends Component {
  state = {
    list: [],
    initialVolume: 0.5,
    loaded: false,
    prepared: false
  };
  componentDidMount() {
    if (!this.props.injected) {
      this.props.actions.handleHeaderConfig(route[1].headerConfig);
    }
    this.props.actions.getYogaAudio().then(response => {
      this.setState(prev => ({
        list: this.props.audioList.map((item, i) => {
          item.active = false;
          item.initialPlay = false;
          return item;
        }),
        loaded: true
      }),()=>setTimeout(()=>this.setState({prepared: true})));
    });
  }

  toggleAudioState = i => {
    let audio = this.refs["audio_" + i];
    if (!this.state.list[i].initialPlay) {
      audio.volume = this.state.initialVolume;
      this.state.list[i].initialPlay = true;
      this.forceUpdate();
    }
    this.state.list[i].active = !this.state.list[i].active;
    console.log(this.refs["audio_" + i].volume);
    audio.paused ? audio.play() : audio.pause();
    this.forceUpdate();
  };

  onHandleVolume = i => {
    let audio = this.refs["audio_" + i];
    console.log("is: ", this.refs["volume_" + i].value / 100);
    audio.volume = this.refs["volume_" + i].value / 100;
    console.log("volume is ", audio.volume);
  };

  render() {
    return this.state.loaded ? (
      <CSSTransition timeout={500} in={this.state.prepared} classNames="common">
        <div className="audio">
          <div className="audio__title">{translate('sounds_title','Sounds')}</div>
          {/* <div className="audio__subtitle">for your meditation</div> */}
          <div className="audio__list">
            {this.state.list.map((itm, i) => (
              <div
                key={i}
                className={"audio__list__item" + (itm.active ? " active" : "")}
              >
                <div
                  onClick={() => this.toggleAudioState(i)}
                  className="audio__list__item__image"
                  style={{ backgroundImage: "url(" + itm.icon + ")" }}
                />
                <div className="audio__list__item__name">{itm.name}</div>
                <audio volume={0.5} className="audio" ref={"audio_" + i}>
                  <source src={itm.music} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
                {itm.active && (
                  <input
                    onChange={() => this.onHandleVolume(i)}
                    ref={"volume_" + i}
                    type="range"
                    min="0"
                    max="100"
                    step="1"
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </CSSTransition>
    ) : (
      <Loading />
    );
  }
}

export default connect(
  state => ({
    navConfig: state.navConfig,
    filter: state.filter,
    audioList: state.audioList
  }),
  dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
)(MeditationAudio);

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../store/actions'
// import {history} from '../../index'
import { cookies } from '../../index'

import ChangePassword from './components/ChangePass'
import ResetPassword from './components/ResetPass'

import { translate } from '../../utils/location'
import store from '../../store'

import { CSSTransition } from 'react-transition-group'

import './profile.scss'

import female from '../../assets/images/female_img.png'
import male from '../../assets/images/male_img.png'
import submit from '../../assets/images/submit_button.svg'

import { setActiveGender, getActiveGender, unsubscribe } from './api'

import route from './route'

let instructors = [
  {
    name: 'Robyn Wright',
    descr: `!I am a Yoga Alliance 200 hour certified teacher`,
    image: female,
    side: 'left',
    id: 1,
    translatedName: translate('trainer_1_name', 'Robyn Wright'),
    translatedDescr: translate('trainer_1_desc', `info`),
  },
  {
    name: 'Jim Miller',
    descr: `!I design classes that explore the transformative power 
    of yoga on personal focus, power, and perspective`,
    image: male,
    side: 'right',
    id: 2,
    translatedName: translate('trainer_2_name', 'Robyn Wright'),
    translatedDescr: translate('trainer_2_desc', `info`),
  },
]

let userInfoChars = [
  {
    type: 'simple',
    charKey: translate('profile_info_user_id', 'User ID'),
    val: '98456234',
    key: 'profile_info_user_id',
    fallback: 'User ID',
  },
  {
    type: 'simple',
    charKey: translate('profile_info_status', 'Subscribe status'),
    val: 'active',
    color: '#7ed321',
    key: 'profile_info_status',
    fallback: 'Subscribe status',
  },
  {
    type: 'simple',
    charKey: translate('profile_info_valid_until', 'Valid Until'),
    val: 'Dec 5, 2019',
    key: 'profile_info_valid_until',
    fallback: 'Valid Until',
  },
]

const passManipulationLocales = ['pt', 'fr', 'be']

const localesWithNoUnsubBtn = [
  'gr',
  'mx',
  'za',
  'co',
  'iq',
  'upstream',
  'u',
  'ps',
]

class Profile extends Component {
  state = {
    activeClass: '',
    loaded: false,
    infoChars: [],
    langInitialized: false,
    unsubscribe: undefined,
    storeUnsub: null,
    femaleTrainerName: '',
    femaleTrainerDescr: '',
    maleTrainerName: '',
    maleTrainerDescr: '',
    isUnsubscribed: cookies.get('isUnsubscribed'),
    passWasChangedManually: false,
  }
  componentDidMount() {
    store.subscribe(() => {
      if (store.getState().langInitialized) {
        this.doTranslation(userInfoChars)
        this.setState(() => ({
          femaleTrainerName: translate('trainer_1_name', instructors[0].name),
          femaleTrainerDescr: translate('trainer_1_desc', instructors[0].descr),
          maleTrainerName: translate('trainer_2_name', instructors[1].name),
          maleTrainerDescr: translate('trainer_2_desc', instructors[1].descr),
        }))
      }
    })
    this.props.actions.handleHeaderConfig(route[0].headerConfig)

    this.setState({ loaded: true })

    store.subscribe(() => {
      userInfoChars[0].val = this.props.userInfo.name
      userInfoChars[2].val = this.props.userInfo.expire_date
    })
    getActiveGender().then((resp) => {
      let filtered = instructors.filter((itm) => itm.id === parseInt(resp.id))
      if (filtered.length) {
        this.setState({ activeClass: filtered[0].side + '_active' })
      }
    })
  }

  setPassWasUpdated = (passWasChangedManually) =>
    this.setState((_) => ({ passWasChangedManually }))

  doTranslation = (list) => {
    let preparedList = list.map((item) => {
      item.charKey = translate(item.key, item.fallback)
      return item
    })
    this.setState(() => ({ itemsList: preparedList, langInitialized: true }))
  }

  setActiveGender = (activeClass, id) => {
    let newClass = this.state.activeClass === activeClass ? '' : activeClass
    this.setState({ activeClass: newClass })
    setActiveGender(id)
  }

  onSubmit = () => {
    cookies.remove('username')
    window.location.href =
      window.location.origin + '/subscription/unsubscribe.php'
  }

  onUnsubscribe = () => {
    window.location.href =
      window.location.origin + '/subscription/za/land/unsubscribe.html'
  }

  render() {
    const { passedLang, lang, userInfo, locale, isAuthorized } = this.props
    const {
      femaleTrainerName,
      femaleTrainerDescr,
      maleTrainerName,
      maleTrainerDescr,
      loaded,
      activeClass,
      isUnsubscribed,
    } = this.state
    return (
      <CSSTransition
        in={loaded}
        timeout={500}
        classNames="common"
        unmountOnExit
      >
        <div className="profile">
          <div className="profile__title">
            {translate('profile_title', 'Profile')}
          </div>
          <div className="profile__subtitle">
            {translate('profile_subtitle', 'Choose your instructor')}
          </div>
          <div className={`profile__gender ${activeClass}`}>
            <div
              className={'profile__gender__side left'}
              onClick={() => this.setActiveGender('left_active', 1)}
            >
              <CSSTransition
                in={!!activeClass}
                timeout={500}
                classNames="common"
                unmountOnExit
              >
                <div className="profile__gender__side__choosed">
                  <img src={submit} alt="choosed" />
                </div>
              </CSSTransition>
              <CSSTransition
                in={!!activeClass}
                timeout={500}
                classNames="common"
                unmountOnExit
              >
                <div className="profile__gender__side__info">
                  <div className="profile__gender__side__info__title">
                    {femaleTrainerName.split(' ').map((w, i) => (
                      <p key={i}>{w}</p>
                    ))}
                  </div>
                  <div className="profile__gender__side__info__descr">
                    {femaleTrainerDescr}
                  </div>
                </div>
              </CSSTransition>
              <div className="profile__gender__side__img">
                <img src={female} alt="female_instructor" />
              </div>
            </div>
            <div
              className={'profile__gender__side right'}
              onClick={() => this.setActiveGender('right_active', 2)}
            >
              <CSSTransition
                in={!!activeClass}
                timeout={500}
                classNames="common"
                unmountOnExit
              >
                <div className="profile__gender__side__choosed">
                  <img src={submit} alt="choosed" />
                </div>
              </CSSTransition>
              <CSSTransition
                in={!!activeClass}
                timeout={500}
                classNames="common"
                unmountOnExit
              >
                <div className="profile__gender__side__info">
                  <div className="profile__gender__side__info__title">
                    {maleTrainerName.split(' ').map((w, i) => (
                      <p key={i}>{w}</p>
                    ))}
                  </div>
                  <div className="profile__gender__side__info__descr">
                    {maleTrainerDescr}
                  </div>
                </div>
              </CSSTransition>
              <div className="profile__gender__side__img">
                <img src={male} alt="male_instructor" />
              </div>
            </div>
          </div>
          {(locale === 'bh' || locale === 'pt') && (
            <div className="profile__user_info">
              <div className="profile__user_info__char">
                <div className="profile__user_info__char__key">
                  {translate('profile_info_user_id', 'User ID!')}
                </div>
                <div className="profile__user_info__char__val">
                  {userInfo.name}
                </div>
              </div>
              <div className="profile__user_info__char">
                <div className="profile__user_info__char__key">
                  {translate('profile_info_status', 'Subscribe status!')}
                </div>
                {!isUnsubscribed && (
                  <div
                    className="profile__user_info__char__val"
                    style={{
                      color:
                        userInfo.account_status === 'active' ? '#7ed321' : '',
                    }}
                  >
                    {userInfo.account_status}
                  </div>
                )}
                {isUnsubscribed && (
                  <div className="profile__user_info__char__val">canceled</div>
                )}
              </div>
              <div className="profile__user_info__char">
                <div className="profile__user_info__char__key">
                  {translate(
                    'profile_info_subscription_date',
                    'Subscription date!'
                  )}
                </div>
                <div className="profile__user_info__char__val">
                  {userInfo.subscribe_date}
                </div>
              </div>
              <div className="profile__user_info__char">
                <div className="profile__user_info__char__key">
                  {translate('profile_info_valid_until', 'Valid Until!')}
                </div>
                <div className="profile__user_info__char__val">
                  {userInfo.expire_date}
                </div>
              </div>
            </div>
          )}
          {!isUnsubscribed && !localesWithNoUnsubBtn.includes(locale) && (
            <div onClick={this.onSubmit} className="profile__unsubscribe">
              <div className="profile__unsubscribe__btn">
                {translate('profile_unsubscribe_btn', 'Unsubscribe')}
              </div>
            </div>
          )}
          {locale === 'ps' && !isAuthorized ? (
            <div onClick={this.onSubmit} className="profile__unsubscribe">
              <div className="profile__unsubscribe__btn">
                {translate('profile_unsubscribe_btn', 'Unsubscribe')} *
              </div>
            </div>
          ) : (
            <br />
          )}
          {locale === 'za' && (
            <>
              <div className="profile__unsubscribe">
                <div
                  onClick={this.onUnsubscribe}
                  className="profile__unsubscribe__btn"
                >
                  {translate('profile_unsubscribe_btn', 'Unsubscribe')}
                </div>
              </div>
            </>
          )}
          {locale === 'mx' && (
            <>
              <div className="profile__unsubscribe">
                <div
                  onClick={(_) =>
                    (window.location.href =
                      window.location.origin +
                      '/subscription/mx/land/unsubscribe.html')
                  }
                  className="profile__unsubscribe__btn"
                >
                  {translate('profile_unsubscribe_btn', 'Unsubscribe')}
                </div>
              </div>
            </>
          )}
          {locale === 'at' && (
            <div className="links">
              <p>
                <Link to="/content/at/faq">Über Ihr Abo</Link>
              </p>
              <p>
                <Link to="/content/at/privacy-policy">
                  Datenschutz-bestimmungen
                </Link>
              </p>
              <p>
                <Link to="/content/at/Reference-to-right-of-withdrawal">
                  Hinweis auf Rücktrittsrecht
                </Link>
              </p>
              <p>
                <Link to="/content/at/Terms-of-Use">Nutzungsbedingungen</Link>
              </p>
              <p>
                <a href="https://sfbtech.org/at-imprint.html" target="_blank">
                  Imprint
                </a>
              </p>
            </div>
          )}
          {passManipulationLocales.includes(locale) && cookies.get('username') && (
            <React.Fragment>
              <div className="profile__unsubscribe">
                <div
                  onClick={() => {
                    cookies.remove('username')
                    window.location.href =
                      window.location.origin +
                      `/subscription/${locale}/logout.php`
                  }}
                  className="profile__unsubscribe__btn"
                >
                  {translate('profile_logout_btn', 'Logout.')}
                </div>
              </div>
              <div className="profile__change-pass">
                <ChangePassword
                  locale={locale}
                  setPassWasUpdated={this.setPassWasUpdated}
                />
              </div>
              <div className="profile__reset-pass">
                <ResetPassword
                  locale={locale}
                  passWasChangedManually={this.state.passWasChangedManually}
                />
              </div>
            </React.Fragment>
          )}
          {locale !== 'at' &&
            locale !== 'upstream' &&
            locale !== 'u' &&
            locale !== 'iq' && (
              <div className="profile__terms">
                {locale !== 'gr' ? (
                  <a href={`/terms.php?source=app&lang=${passedLang}`}>
                    {translate('profile_terms_link_text', 'Terms & Conditions')}
                  </a>
                ) : (
                  <div style={{ paddingTop: 50 }}>
                    <Link to="/content/gr/terms">ΟΡΟΙ ΧΡΗΣΗΣ</Link>
                  </div>
                )}
              </div>
            )}
        </div>
      </CSSTransition>
    )
  }
}

export default connect(
  (state) => ({
    navConfig: state.navConfig,
    filter: state.filter,
    userInfo: state.userInfo,
    passedLang: state.passedLang,
    locale: state.lang,
    isAuthorized: state.isAuthorized,
  }),
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
  })
)(Profile)

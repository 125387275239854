import Axios from "axios";

export function setActiveGender(id){
    Axios.get(`/api.php?set_trainer_id=${id}`).then(resp => console.log('trainer', resp))
}

export function getActiveGender(){
    return Axios.get(`/api.php?trainer`).then(resp => resp.data.YOGA_APP.trainer)
}

export function getUserInfo(){
    return Axios.get(`/api.php?account`).then(resp => resp.data.account)
}

export function changePass(data,locale){
    return Axios.post(`/subscription/${locale}/changePassword.php`,data)
}

export const unsubscribe = _ => Axios.post('http://yogaforyou.club/za/unsubscribe.php',{ unsubscribe: 'confirmed' },{
    headers: {'x-vc-acr':'4ed28d3a058e7e9179812afac61cfe20fc4e0ad727324461e3d26e16bc86f3f44302f88ff7481ff0c1ae9d237bcd72d485ae6795e0d9b14ba389d8cf5f6fb54a345979f8481d95d0cd0e022e20d77c590a9f43999814633aa337f59d8057d5474036bf8ce163145c138d08c5cea258f3d690be8fd78a3b514cfdfe02a8fef8f427fe35999d02335bb70e1679bb8cdbb17fde46730979b3aacb1c187c5b4bf71484a0dfb03a228a21a4c8be6fdeb56d56bca2a0c32b161e4c1eae97bc5952d9d8fa26c5d4fe90c15c8a3083702f5064cc0e0c9ff02c50ac81b03355ba6e3d86283768835bbb25eec7c2676af5fb0956405827d9968d4622f521fa80ca4e5ea09a'}
})

export const resetPass = locale => Axios.get(`/subscription/${locale}/updatePassword.php`)

export const checkUnsubscribed = _ => Axios.get('/subscription/unsubscribe.php')
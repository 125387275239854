import React from "react";
import { connect } from "react-redux";

import langIcon from "../../../../assets/images/icons/lang.svg";

import "./select.scss";

const SelectLang = ({ onChange, options = [], lang }) => {
  return (
    <div className="select">
      <div className="select__input">
        <select onChange={onChange} defaultValue={lang}>
          {options.map((opt, i) => (
            <option value={opt.value} key={i}>
              {opt.label}
            </option>
          ))}
        </select>
      </div>
      <div className="select__icon">
        <img src={langIcon} alt="" />
      </div>
    </div>
  );
};

export default connect(state => ({ lang: state.passedLang }), null)(SelectLang);

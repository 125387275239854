import thumb_beginner from '../../assets/images/beginner_thumb.png'
import thumb_interm from '../../assets/images/interm_thumb.png'
import thumb_advanced from '../../assets/images/advanced_thumb.png'

export default {
    sort: {
        byLevel: [
            {
                label: 'Beginner',
                thumb: thumb_beginner,
                color: 'linear-gradient(to top, #45cba4 0%, #bfffb6 100%)',
                id: 'beginner',
                lid: '1'
            },
            {
                label: 'Intermediate',
                thumb: thumb_interm,
                color: 'linear-gradient(to top, #6e8cff 0%, #c3cfff 100%)',
                id: 'intermediate',
                lid: '2'
            },
            {
                label: 'Advanced',
                thumb: thumb_advanced,
                color: 'linear-gradient(#ffa524 0%, #f97511 100%)',
                id: 'advanced',
                lid: '3'
            },
        ],
        byType: [
            {
                label: 'Arm Balance',
                id: '1',
            },
            {
                label: 'Balancing',
                id: '2'
            },
            {
                label: 'Binding',
                id: '3'
            },
            {
                label: 'Backbend',
                id: '4'
            },
            {
                label: 'Core',
                id: '5'
            },
            {
                label: 'Standing',
                id: '6'
            },
            {
                label: 'Seated',
                id: '7'
            },
            {
                label: 'Forward Bend',
                id: '8'
            },
            {
                label: 'Strengthening',
                id: '9'
            },
        ]
    }
}
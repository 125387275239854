import React from "react";

import "./guide.scss";

export default () => (
  <div className="guide" style={{ padding: 20 }}>
    <div className="disclaimer">
      La información y los ejercicios provistos por Yoga for you son solo para
      fines educativos y de entretenimiento, y deben usarse según su criterio y
      bajo orientación profesional. No se ofrecen como reemplazo o sustituto de
      un consejo o tratamiento médico profesional. Si cree que puede sufrir una
      discapacidad física o emocional, le recomendamos consultar a un médico
      profesional antes de iniciar este o cualquier otro programa de ejercicios.
    </div>
    <div className="guide__title" style={{ textAlign: "center" }}>
      <h1>“Yoga For You” Guía del usuario para el portal</h1>
    </div>
    <br />
    <div className="guide__body">
      <div className="guide__item">
        <div className="guide__info">
          <div className="guide__icon">
            <img
              src={require("../../assets/images/icons/person_icon.svg")}
              alt="person"
            />
          </div>
          <div className="guide__text">
            <p>1. Haga clic en la pestaña «Elija su instructor».</p>
            <p>
              En esta sección puede elegir con quien le gustaría continuar su
              experiencia de yoga. Dependiendo de su elección, aparecerán los
              respectivos videos de entrenamiento y ejercicios de yoga en la
              sección del portal «posturas de yoga»
            </p>
          </div>
        </div>
        <div className="guide__screenshots">
          {Array.from({ length: 4 }).map((item, i) => (
            <div className="guide__screenshots-item">
              <img
                src={require(`../../assets/images/templates/guide/1/${i +
                  1}.png`)}
                alt={`screenshot-guide-item-${i + 1}`}
              />
            </div>
          ))}
          <div className="devider"></div>
        </div>
      </div>

      <br />
      <div className="guide__item">
        <div className="guide__info">
          <div className="guide__icon">
            <img
              src={require("../../assets/images/icons/yoga_icon.svg")}
              alt="person"
            />
          </div>
          <div className="guide__text">
            <p>2. Vaya a la sección «posturas de yoga».</p>
            <p>
              Aquí hemos colocado las descripciones textuales detalladas (con
              los beneficios y precauciones) de cada una de las asanas.La alta
              calidad de los gráficos le permitirá seguir los pasos
              correctamente y evitar errores.
            </p>
            <p>
              Seleccione la asana que desee y haga clic en el botón «Iniciar
              entrenamiento». Cada asana tiene un tutorial de vídeo para
              garantizar la mejor experiencia del usuario. ¡Nuestros
              instructores de yoga certificados, Robyn Wright y Jim Miller, le
              acercarán al mundo del yoga!
            </p>
          </div>
        </div>
        <div className="guide__screenshots">
          {Array.from({ length: 5 }).map((item, i) => (
            <div className="guide__screenshots-item">
              <img
                src={require(`../../assets/images/templates/guide/2/${i +
                  1}.png`)}
                alt={`screenshot-guide-item-${i + 1}`}
              />
            </div>
          ))}
          <div className="devider"></div>
        </div>
      </div>

      <br />

      <div className="guide__item">
        <div className="guide__info">
          <div className="guide__icon">
            <img
              src={require("../../assets/images/icons/music_icon.svg")}
              alt="person"
            />
          </div>
          <div className="guide__text">
            <p>3. Vaya a la sección «Música de meditación».</p>
            <p>
              Al terminar la rutina de yoga, tómese unos minutos para relajarse
              antes de volver a las ocupaciones del día a día.
            </p>
            <p>
              La música ha demostrado mejorar la concentración durante la
              meditación. Es por ello que hemos añadido estas 12 pistas.
            </p>
          </div>
        </div>
        <div className="guide__screenshots">
          {Array.from({ length: 3 }).map((item, i) => (
            <div className="guide__screenshots-item">
              <img
                src={require(`../../assets/images/templates/guide/3/${i +
                  1}.png`)}
                alt={`screenshot-guide-item-${i + 1}`}
              />
            </div>
          ))}
          <div className="devider"></div>
        </div>
      </div>

      <br />

      <div className="guide__item">
        <div className="guide__info">
          <div className="guide__icon">
            <img
              src={require("../../assets/images/icons/lotus_icon.svg")}
              alt="person"
            />
          </div>
          <div className="guide__text">
            <p>4. Vaya a la sección «Fondo de meditación».</p>
            <p>
              Los sonidos de meditación también pueden escucharse mientras usa
              los fondos de meditación.
            </p>
            <p>
              Estos fondos pueden ser utilizados durante la práctica de yoga
              guiada, y le permitirán conectarse a sí mismo a un nivel emocional
              y espiritual más profundo. Puede deslizar y elegir entre 20 fondos
              animados y estáticos.
            </p>
          </div>
        </div>
        <div className="guide__screenshots">
          {Array.from({ length: 4 }).map((item, i) => (
            <div className="guide__screenshots-item">
              <img
                src={require(`../../assets/images/templates/guide/4/${i +
                  1}.png`)}
                alt={`screenshot-guide-item-${i + 1}`}
              />
            </div>
          ))}
          <div className="devider"></div>
        </div>
      </div>

      <br />

      <div className="guide__item">
        <div className="guide__info">
          <div className="guide__icon">
            <img
              src={require("../../assets/images/icons/book_icon.svg")}
              alt="person"
            />
          </div>
          <div className="guide__text">
            <p>5. Vaya a la sección «Teoría».</p>
            <p>
              En esta sección encontrará artículos sobre la historia del yoga,
              su filosofía y sus principios fundamentales, que, a usted, como
              yogui practicante, le aportarán un bagaje intelectual espiritual y
              moral que ampliará su perspectiva de la vida. La sección «Teoría»
              se actualiza semanalmente para que siempre haya un nuevo artículo
              interesante que leer.
            </p>
          </div>
        </div>
        <div className="guide__screenshots">
          {Array.from({ length: 4 }).map((item, i) => (
            <div className="guide__screenshots-item">
              <img
                src={require(`../../assets/images/templates/guide/5/${i +
                  1}.png`)}
                alt={`screenshot-guide-item-${i + 1}`}
              />
            </div>
          ))}
          <div className="devider"></div>
        </div>
      </div>
      <br />
    </div>
  </div>
);

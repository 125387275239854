import React, { Component } from "react";

import { translate } from "../../utils/location";
import store from "../../store";

import animation from "lottie-web";

import yoga_icon from "../../assets/images/icons/yoga_icon.svg";
import star_icon from "../../assets/images/icons/star_icon.svg";
import lotus_icon from "../../assets/images/icons/lotus_icon.svg";
import book_icon from "../../assets/images/icons/book_icon.svg";
import button_arrow from "../../assets/images/button_arrow.svg";
import rounded_top_border from "../../assets/images/rounded_top_border.svg";
import "./home.scss";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions";

import * as scroll from "react-scroll";

import route from "./route";

import PresentationCards from "./PresentationCards";
let ScrollLink = scroll.Link;

let welcomeText = `Welcome to Yoga for You club, 
a place where you will discover yourself`;

class HomePage extends Component {
  state = {
    unsubscribe: undefined,
    langInitialized: false,
    welcomeTextFallback: `Welcome to Yoga for You club, 
    a place where you will discover yourself`
  };

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.langInitialized !== prevState.langInitialized) {
  //     return {
  //       // ...prevState,
  //       langInitialized: nextProps.langInitialized
  //     };
  //   }
  //   else return null;
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log(prevState.langInitialized,this.props.langInitialized)
  //   if(prevState.langInitialized !== this.props.langInitialized){
  //     if(this.props.langInitialized){
  //       this.doTranslation('home_welcome', this.state.welcomeTextFallback)
  //     }
  //   }
  //   console.log(this.props.langInitialized)
  // }

  componentDidMount() {
    this.props.actions.handleHeaderConfig(route[0].headerConfig);
    animation.loadAnimation({
      container: document.querySelector(".home__logo__back"),
      renderer: "svg",
      loop: false,
      autoplay: true,
      path: "logo.json"
    });

    this.setState(() => ({
      unsubscribe: store.subscribe(() => {
        if (this.state.langInitialized !== store.getState().langInitialized) {
          this.doTranslation("home_welcome", this.state.welcomeTextFallback);
        }
      })
    }));
  }
  componentWillUnmount() {
    if (this.state.unsubscribe) {
      this.state.unsubscribe();
    }
  }

  doTranslation = (key, fallback) => {
    welcomeText = translate(key, fallback);
    this.setState(() => ({
      langInitialized: true
    }));
  };
  render() {
    const { lang } = this.props
    return (
      <div className="home">
        <div className="home__screen">
          <div className="home__logo">
            <div className="home__logo__back"></div>
          </div>
          <div className="home__descr side_padding">
            {translate(
              "home_welcome",
              `Welcome to Yoga for You club, 
    a place where you will discover yourself`
            )}
            {lang === 'mx' && (
              <p>Te recomendamos utilizar una conexión Wi-Fi al navegar por el portal.</p>
            )}
          </div>

          <div className="border">
            <div className="icons_list side_padding">
              <div className="icons_list__item">
                <img src={yoga_icon} alt="icon" />
              </div>
              <div className="icons_list__item">
                <img src={star_icon} alt="icon" />
              </div>
              <div className="icons_list__item">
                <img src={lotus_icon} alt="icon" />
              </div>
              <div className="icons_list__item">
                <img src={book_icon} alt="icon" />
              </div>
            </div>
            <div className="border__inner">
              <div className="border__inner__template">
                <img src={rounded_top_border} alt="border" />
              </div>
              <ScrollLink to="card-0" spy={true} smooth={true} offset={0}>
                <div id="home-screen-btn" className="border__inner__button">
                  <img src={button_arrow} alt="arrow" />
                </div>
              </ScrollLink>
            </div>
          </div>
        </div>
        <div className="home__cards">
          <PresentationCards />
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    navConfig: state.navConfig,
    filter: state.filter,
    meditation: state.meditation,
    langInitialized: state.langInitialized,
    lang: state.lang
  }),
  dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
)(HomePage);

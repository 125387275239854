import React, { Component } from 'react'
// import Axios from 'axios'

// import Loading from '../../components/loading'

// import store from '../../store'
// import * as actions from '../../store/actions'
// import {cookies} from '../../index'

// import { getUserInfo } from '../../components/profile/api'

// const PROTECTED_KEY = 'dHcpDeur7EgKSLST'

export default WrappedComponent => class WithUser extends Component {
  // state = {
  //   isLoaded: false
  // }

  // async componentDidMount(){
  //   const urlParams = new URLSearchParams(window.location.search);
  //   try{
  //     const userResponse = await getUserInfo()
  //     if(cookies.get('isProtected')){
  //       store.dispatch(actions.setAuth())
  //     }else{
  //       if(urlParams.get('source') === PROTECTED_KEY){
  //         cookies.set('isProtected','true')
  //         store.dispatch(actions.setAuth())
  //       }else{
  //         try{
  //           const authResponse = await Axios.get('/subscription/authorize.php')
  //           if(authResponse){
  //             if(authResponse.data){
  //               store.dispatch(actions.authLink(authResponse.data))
  //             }else{
  //               store.dispatch(actions.setAuth())
  //             }
  //           }
  //         } catch {
  //           console.log('error while authorizing')
  //         } 
  //       }
  //     }
  //     userResponse && store.dispatch(actions.setUserInfo(userResponse))
  //     console.log('user info hoc')
  //   }catch{
  //     const response = null;
  //     console.log('error')
  //   }
  //   this.setState(_ => ({isLoaded: true}))
  // }

  render(){
    // const { isLoaded } = this.state
    // return isLoaded ? <WrappedComponent {...this.props} /> : <Loading />
    return <WrappedComponent {...this.props} /> 
  }
}
import React from "react";
import { Element, Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";

import {translate} from "../../../utils/location"

import arrow_back from "../../../assets/images/icons/arrow_back.svg";
import play_icon from "../../../assets/images/icons/play_icon.svg";
import repeat_icon from "../../../assets/images/card_elems/repeat.svg";
import overlay from "../../../assets/images/card_elems/overlay_group.svg";
import arrow_down from "../../../assets/images/card_elems/arrow_down.svg";
import devider from "../../../assets/images/card_elems/devider.svg";
import nav_arrow from "../../../assets/images/prev_arrow.svg";

import "../pose.scss";

export default function Pose(props) {
  const {
    //variables
    lang,
    programMode,
    paused,
    loaded,
    ended,
    data,
    //functions
    handleColorScheme,
    togglePlay,
    onEnd,
    hasNext,
    navigate
  } = props;
  return (
    <div className="pose">    
      <div className="pose__main_screen">
        <div className="pose__main_screen__back">
          <Link to={programMode ? "/programs" : "/poses"} onClick={()=>document.querySelector('.app').removeAttribute('data-reversed')}>
            <img
              src={arrow_back}
              alt="back_arrow"
              onLoad={e => handleColorScheme("arrow_back", e.target)}
            />
          </Link>
        </div>
        <div
          className={
            "pose__main_screen__central_space space " +
            (paused ? "transparent_wall" : "")
          }
        >
          {loaded && (
            <video
              onClick={togglePlay}
              onEnded={onEnd}
              id="pose_video"
              webkit-playsinline="true"
              playsInline
            >
              <source src={data.video_url} type="video/mp4" />
              <source src={data.video_url} type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          )}

          {paused && (
            <div className="space__info">
              {programMode && (
                <div className="space__info__navigation">
                  {hasNext(data.id, "prev") && (
                    <div
                      className="space__info__prev"
                      onClick={() => navigate(data.id, "prev")}
                    >
                      <img
                        src={nav_arrow}
                        alt="prev-pose"
                        onLoad={e => handleColorScheme("nav-arrow", e.target)}
                      />
                    </div>
                  )}
                  {hasNext(data.id, "next") && (
                    <div
                      className="space__info__next"
                      onClick={() => navigate(data.id, "next")}
                    >
                      <img
                        src={nav_arrow}
                        alt="next-pose"
                        onLoad={e => handleColorScheme("nav-arrow", e.target)}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="space__info__play" onClick={togglePlay}>
                {ended ? (
                  <img
                    src={repeat_icon}
                    alt="repeat-icon"
                    onLoad={e => handleColorScheme("repeat_button", e.target)}
                  />
                ) : (
                  <img
                    src={play_icon}
                    alt="play-icon"
                    onLoad={e => handleColorScheme("play_button", e.target)}
                  />
                )}
              </div>
              <div className="space__info__title">{data.yoga_title}</div>
              <div className="space__info__subtitle">
                {data.yoga_sanskrit_title}
              </div>
            </div>
          )}
        </div>
        <div className="pose__main_screen__footer footer">
          <div className="footer__label">
            <div className="footer__label__small">{translate('single_pose_watch_video_or_read','Wacth video or read')}</div>
            <div className="footer__label__big">{translate('single_pose_step_by_step_instruction','Step by Step instruction')}</div>
          </div>
          <div className="footer__arrow">
            <ScrollLink to="step-0" spy={true} smooth={true} offset={-15}>
              <img
                src={arrow_down}
                alt="arrow_down"
                onLoad={e => handleColorScheme("arrow_down", e.target)}
              />
            </ScrollLink>
          </div>
          <div className="footer__overlay">
            <object
              data={overlay}
              aria-label="overlay"
              onLoad={e =>
                handleColorScheme(
                  "overlay",
                  e.target.contentDocument.querySelector("svg")
                )
              }
            />
          </div>
        </div>
      </div>
      <div className="pose__steps_list" style={{direction: lang === 'iq' ? 'rtl' : 'ltr'}}>
        {data.yoga_step.map((item, i) => (
          <Element key={i} name={"step-" + i}>
            <div className="pose__steps_list__item">
              <div className="pose__steps_list__item__label">
                {translate('single_pose_step','step')}
                <span
                  style={{ color: data.colors[0] }}
                  className="pose__steps_list__item__label__num"
                >
                  {i + 1}
                </span>
              </div>
              <div className="pose__steps_list__item__image">
                <img src={data.yoga_step_images[i]} alt={"step-" + i + 1} />
              </div>
              <div className="pose__steps_list__item__text">{item}</div>
              <div className="pose__steps_list__item__devider">
                <img src={devider} alt="devider" />
              </div>
            </div>
          </Element>
        ))}
      </div>
    </div>
  );
}

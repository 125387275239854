import VisualMeditation from './Visual'
import AudioMeditation from './Audio'

export default [
    {
        path: '/visual-meditation',
        component: VisualMeditation,
        isExact: false,
        headerConfig: {
            isDisplayed: true,
            isFixed: true,
            isOpened: false,
            options: {
                searchInput: false,
                filter: false,
                backButton: false,
                backButtonLink: '',
                soundButton: true,
                closeButton: false
            }
        }
    },
    {
        path: '/audio-meditation',
        component: AudioMeditation,
        isExact: false,
        headerConfig: {
            isDisplayed: true,
            isFixed: false,
            isOpened: false,
            options: {
                searchInput: false,
                filter: false,
                backButton: false,
                backButtonLink: '',
                soundButton: false,
                closeButton: false,
                profileButton: true
            }
        }
    }
]
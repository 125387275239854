import React, { Component } from "react";
import "./programs.scss";
import ProgramCards from "./ProgramCards";

import { TweenLite, Back } from "gsap";
import { getGradient } from "../../utils/themeSettings";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions";

import { getCatList } from "./api";

import route from "./route";
import Loading from "../loading";

class Programs extends Component {
  state = {
    loaded: false,
    prepared: false,
    list: []
  };
  cards = null;
  componentDidMount() {
    this.props.actions.handleHeaderConfig(route[0].headerConfig);
    this.props.actions.getYogaLevels().then(levels => {
      getCatList().then(resp => {
        this.setState(
          {
            list: resp.map(itm => {
              itm.gradient = getGradient(itm.category_colors);
              itm.level = this.getLevel(itm.category_level_id);
              return itm;
            }),
            loaded: true
          },
          () => {
            setTimeout(() => {
              this.setState({ prepared: true });
              TweenLite.from(this.cards, 1, {
                x: -500,
                ease: Back.easeOut
              });
            });
          }
        );
      });
    });
  }
  getLevel = id => {
    let filtered = this.props.levels.filter(lv => lv.lid === id);
    return filtered.length ? filtered[0].level_name.toLowerCase() : "";
  };
  render() {
    return this.state.loaded ? (
      <div className="programs" ref={e => (this.cards = e)}>
        <ProgramCards data={this.state.list} />
      </div>
    ) : (
      <Loading />
    );
  }
}

export default connect(
  state => ({
    navConfig: state.navConfig,
    levels: state.levelsList
  }),
  dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
)(Programs);

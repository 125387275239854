import React from 'react'
import { connect } from 'react-redux'
import { translate } from '../../../../utils/location'

import { changePass } from '../../api'

import './change-pass.scss'

class ChangePass extends React.Component {
  state = {
    formMessage: '',
    fields: [
      {
        id: 'old-pass',
        value: '',
        placeholder: 'Senha Antiga',
        placeholderTId: 'old_password',
        message: '',
        rules: {
          required: (value) =>
            !!value.length || ['required_field', 'Required field'],
          'valid-chars': (value) =>
            /^[a-zA-Z0-9_.-]*$/g.test(value) || [
              'invalid_character_type',
              'Invalid character type. Do not use special characters',
            ],
        },
      },
      {
        id: 'new-pass',
        value: '',
        placeholder: 'Nova senha',
        placeholderTId: 'new_password',
        message: `O tamanho mínimo da senha é de 4 caracteres.
        Não use senhas de outros sites ou opções que os invasores possam escolher facilmente.`,
        rules: {
          required: (value) =>
            !!value.length || ['required_field', 'Required field'],
          'valid-chars': (value) =>
            /^[a-zA-Z0-9_.-]*$/g.test(value) || [
              'invalid_character_type',
              'Invalid character type. Do not use special characters',
            ],
          length: (value) =>
            value.length >= 4 || [
              'password_length_must_be',
              'Password length must be the same  or longer than 4 signs',
            ],
        },
      },
      {
        id: 'confirm-pass',
        value: '',
        placeholder: 'Confirme a Senha',
        placeholderTId: 'confirm_password',
        message: '',
        rules: {
          required: (value) =>
            !!value.length || ['required_field', 'Required field'],
          'valid-chars': (value) =>
            /^[a-zA-Z0-9_.-]*$/g.test(value) || [
              'invalid_character_type',
              'Invalid character type. Do not use special characters',
            ],
          equal: (value) => {
            const { fields } = this.state
            const newPassField = fields.find((f) => f.id === 'new-pass')
            return (
              (newPassField && value === newPassField.value) || [
                'passwords_have_to_match',
                'passwords have to match',
              ]
            )
          },
        },
      },
    ],
    formVisibility: false,
  }

  toggleFormVisibility = () => {
    this.setState((prev) => ({ formVisibility: !prev.formVisibility }))
  }

  onFieldChange = ({ target }, field) => {
    this.setState(
      (prev) => ({
        fields: prev.fields.map((f) => {
          if (f.id === field.id) f.value = target.value
          return f
        }),
      }),
      () => setTimeout(() => this.checkFieldValidity(field), 500)
    )
  }

  setFieldMessage = (id, message) => {
    this.setState((prev) => ({
      fields: prev.fields.map((f) => {
        if (f.id === id) f.message = message
        return f
      }),
    }))
  }

  checkFieldValidity = (field) => {
    if (field) {
      for (let rule in field.rules) {
        if (field.rules[rule](field.value) !== true) {
          const [messageId, messageFallback] = field.rules[rule](field.value)
          this.setFieldMessage(field.id, translate(messageId, messageFallback))
          return
        } else {
          this.setFieldMessage(field.id, '')
        }
      }
    }
  }

  onSubmit = (e) => {
    const { fields } = this.state
    const { userInfo } = this.props

    e.preventDefault()

    for (let i = 0; i < fields.length; i++) {
      if (fields[i].message) {
        this.setState((_) => ({
          formMessage: translate(
            'please_fill_in_all_fields_correctly',
            'Por favor, preencha todos os campos corretamente'
          ),
        }))
        return
      }
    }

    this.setState(
      (_) => ({ formMessage: '' }),
      () => {
        changePass(
          {
            username: userInfo.name,
            old_password: fields[0].value,
            new_password: fields[2].value,
          },
          this.props.locale
        ).then((resp) => {
          console.log('resp', resp)
          if (resp.data.toString() === '4062') {
            this.setState((_) => ({
              formMessage: translate(
                'old_password_is_invalid',
                'Senha antiga inválida'
              ),
            }))
            return
          }
          if (resp.data.toString() === '4061') {
            this.setState((_) => ({
              formMessage: translate(
                'new_password_is_invalid',
                'Senha nova inválida'
              ),
            }))
            return
          }
          this.setState(
            (_) => ({
              formMessage: translate(
                'password_is_successfully_changed',
                'Senha mudada com sucesso'
              ),
            }),
            () => {
              setTimeout(() => {
                this.setState(() => ({ formVisibility: false }))
                this.props.setPassWasUpdated(true)
              }, 1500)
            }
          )
        })
      }
    )
  }

  render() {
    const { formVisibility, formMessage, fields } = this.state
    return (
      <div className="change-pass profile__unsubscribe">
        <button
          className="profile__unsubscribe__btn btn"
          onClick={this.toggleFormVisibility}
        >
          {!formVisibility
            ? translate('change_password', 'Change password')
            : translate('cancel', 'Cancel')}
        </button>
        {formVisibility && (
          <div className="change-pass__form">
            <form onSubmit={this.onSubmit}>
              {fields.map((field, i) => (
                <div key={i} className="change-pass__form-input">
                  <label>
                    <p>{translate(field.placeholderTId, field.placeholder)}</p>
                    <input
                      type="password"
                      placeholder={translate(
                        field.placeholderTId,
                        field.placeholder
                      )}
                      onKeyUp={(e) => this.onFieldChange(e, field)}
                    />
                  </label>
                  {field.message && <p className="message">{field.message}</p>}
                </div>
              ))}
              {formMessage && <p className="message">{formMessage}</p>}
              <button className="btn" type="submit">
                {translate('change_password', 'Change password')}
              </button>
            </form>
          </div>
        )}
      </div>
    )
  }
}

export default connect(
  (state) => ({
    userInfo: state.userInfo,
    locale: state.lang,
  }),
  null
)(ChangePass)

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Loading from '../../../../loading'

import * as actions from "../../../../../store/actions";
import route from '../../../route'
import { getTheoryCategory } from '../../../api'
import { history } from '../../../../../index'

import banner from "../../../../../assets/images/templates/meditation.png";

import "../posts.scss";

class List extends Component {
  state = {
    isLoaded: false,
    category: null
  }
  componentDidMount() {
    const { actions, match } = this.props
    actions.handleHeaderConfig(route[1].headerConfig);
    if (match.params['catId']) {
      getTheoryCategory(match.params['catId'])
        .then(resp => {
          console.log(resp)
          this.setState(_ => ({category: resp.data, isLoaded: true}))
        })
        .catch(err => {
          console.log(err,'error while getting category posts')
          history.push('/theory')
        })
    }else{
      history.push('/theory')
    }
  }
  render() {
    const { category, isLoaded } = this.state
    return (
      isLoaded ? (
        <div className="posts">
          <div className="posts__title">{category.category_title}</div>
          <div className="posts__list">
            {category.posts.map((post, i) => (
              <Link key={i} to={`/theory/${category.category_id}/${post.post_id}`}>
                <div key={i} className="posts__list__item post_card">
                  <div
                    className="post_card__banner"
                    style={{ backgroundImage: "url(" + (post.image ? post.image : banner) + ")" }}
                  />
                  <div className="post_card__info">
                    <div className="post_card__info__title">{post.title}</div>
                    <div className="post_card__info__descr">
                      {post.short_text}
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      ) : (
        <Loading />
      )
    );
  }
}

export default connect(
  state => ({
    navConfig: state.navConfig
  }),
  dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
)(List);

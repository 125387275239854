import i18next from 'i18next'
import store from '../store'
import * as actions from '../store/actions'
import { history } from '../index'
import { existingLangs, interLangCountries } from './countrySettings'

export function defineLocation(langs) {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('lang')
}

export function setLanguage(language) {
  if (
    existingLangs.includes(language) &&
    !interLangCountries.includes(language)
  ) {
  } else {
  }
}

export function setLocation() {
  let definedLang = defineLocation(existingLangs)
  if (definedLang) {
    store.dispatch(actions.changeLang(definedLang))
    localStorage.setItem('lang', definedLang)
    let newPath = window.location.pathname
    history.push(newPath.replace('/' + definedLang, ''))
  } else {
    if (!localStorage.getItem('lang')) {
      setLanguage('en')
    } else {
      setLanguage(localStorage.getItem('lang'))
    }
  }
}

export function translate(key, fallback) {
  const { langInitialized } = store.getState()

  return !langInitialized
    ? fallback
    : i18next.t(key) && i18next.t(key) !== key
    ? i18next.t(key)
    : fallback
}

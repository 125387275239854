import Welcome from './index'

export default [
  {
    path: '/welcome',
    component: Welcome,
    isExact: false,
    headerConfig: {
      isDisplayed: true,
      isFixed: false,
      isOpened: false,
      options: {
        searchInput: false,
        filter: false,
        backButton: false,
        backButtonLink: '',
        soundButton: false,
        closeButton: false,
        profileButton: true
      }
    }
  }
]